<template>
  <v-container>
    <v-row v-if="resteMeals.length > 0">
      <v-col>
        <MealList
          :items="resteMeals"
          @pickupMeal="pickupResteMeal"
          hideBasket
        />
      </v-col>
    </v-row>
    <v-row v-if="resteMeals.length > 0">
      <v-slide-y-reverse-transition>
        <FooterOrder />
      </v-slide-y-reverse-transition>
    </v-row>

    <v-card v-if="resteMeals.length == 0">
      <v-card-text class="text-center">
        <h3>Derzeit sind leider keine Essen verfügbar</h3>
      </v-card-text>
    </v-card>

    <v-dialog :value="showLieferorteDialog" fullscreen overlay-color="white">
      <v-container fluid class="background2"></v-container>
      <v-container fluid class="fill-height pa-0">
        <v-toolbar dark>
          <v-toolbar-title>Lieferort auswählen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showLieferorteDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-row class="fill-height">
          <v-col cols="12" xs="12">
            <v-card
              v-for="(item, index) in userLieferorte"
              :key="index"
              class="daycard ma-3 pt-8 pb-8"
              align="center"
              @click="selectLieferort(item)"
            >
              <v-layout>
                <v-layout column justify-center>
                  <h4>
                    {{ item.standort }}
                  </h4>
                </v-layout>
              </v-layout>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>

    <v-dialog v-model="scanCode" fullscreen>
      <v-toolbar dark>
        <v-toolbar-title>QR Code scannen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="scanCode = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card dark class="fill-height">
        <v-card tile class="scanHeader">
          <v-card-text class="scanHeader"
            >Bitte scanne den QR Code auf deinem Gericht ab</v-card-text
          >
        </v-card>
        <QRCodeScanner v-if="scanCode" @onDecode="onDecode" />
      </v-card>
    </v-dialog>

    <PickupResultDialog
      :status="pickupStatus"
      :pickupError="pickupError"
      :mealAmount="pickupAmount"
    />
  </v-container>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import QRCodeScanner from "@/components/order/QRCodeScanner";
import OrderService from "@/services/OrderService";
import MealList from "@/components/meals/MealList";
import PickupResultDialog from "@/components/pickup/PickupResultDialog";
import FooterOrder from "@/components/FooterOrder";

export default {
  name: "Restekarte",
  components: { QRCodeScanner, MealList, FooterOrder, PickupResultDialog },
  data: () => ({
    order: null,
    scanCode: false,
    pickupAmount: 0,
    pickupMeal: "",
    pickupError: "",
    pickupStatus: false,
  }),
  methods: {
    pickupResteMeal(data) {
      this.pickupStatus = data.pickupStatus;
      this.pickupError = data.pickupError;
      this.pickupAmount = data.amount;

      this.$store
        .dispatch("meal/fetchUnpickedMeals", this.selectedLieferort)
        .then(() => {
          // console.log(result);
          this.showOrderPickupResult = true;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    pickupScan(item) {
      this.scanCode = true;
      this.pickupMeal = item;
    },
    /**
     * @param {string} decodedString - Decoded QR Code as String
     */
    onDecode(decodedString) {
      let qrParts = {
        artnr: "",
        mhd: "",
        charge: "",
      };
      const idArtNr = "(241)";
      const idMhd = "(15)";
      const idCharge = "(10)";
      this.pickupError = null;
      this.pickupStatus = false;

      qrParts.artnr = String(decodedString).substring(
        idArtNr.length,
        decodedString.indexOf(idMhd)
      );
      qrParts.mhd = String(decodedString).substring(
        decodedString.indexOf(idMhd) + idMhd.length,
        decodedString.indexOf(idCharge)
      );
      qrParts.charge = String(decodedString).substring(
        decodedString.indexOf(idCharge) + idCharge.length
      );

      const mhdDate = moment(qrParts.mhd, "YYMMDD");

      if (mhdDate.isBefore(moment())) {
        //Abgelaufen
        this.checkError = `Das abgescannte Gericht ist leider am ${mhdDate.format(
          "DD.MM.YYYY"
        )} abgelaufen! Bitte entsorge dieses Gericht`;
        this.pickupError = `Das Gericht stimmt nicht mit dem abgescannten Gericht überein. Bitte Scanne das richtige Gericht.`;
        this.pickupStatus = false;
        this.showOrderPickupResult = true;
        this.scanCode = false;
        return false;
      }

      if (Number.parseInt(qrParts.artnr) !== this.pickupMeal.meal.gerichtnr) {
        this.pickupError = `Das Gericht stimmt nicht mit dem abgescannten Gericht überein. Bitte Scanne das richtige Gericht.`;
        this.pickupStatus = false;
        this.showOrderPickupResult = true;
        this.scanCode = false;
        return false;
      }

      OrderService.checkQRScan(qrParts.artnr, this.actualOrder[0]._id)
        .then((response) => {
          if (response.data.data.result == true && response.data.data.meal) {
            this.pickupAmount = response.data.data.meal.days.amount;
            this.pickupStatus = true;

            this.pickupMeal.pickup = true;
          } else {
            this.pickupStatus = false;
          }
        })
        .catch((error) => {
          this.pickupStatus = false;
          this.pickupError = error;
        })
        .finally(() => {
          this.scanCode = false;
          this.showOrderPickupResult = true;
        });
    },

    currencyFormat(value = 0) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    totalMeals(item) {
      let sum = 0;
      for (let index in item.days) {
        sum += item.days[index].amount;
      }
      return sum;
    },

    selectLieferort(item) {
      this.selectedLieferort = item._id;
      this.$store
        .dispatch("meal/fetchUnpickedMeals", item._id)
        .then(() => {
          this.showLieferorteDialog = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filteredMeals() {
      if (this.filterKategorien && this.filterKategorien.length > 0) {
        return this.$store.getters["meal/getResteMealList"].filter((item) => {
          return this.filterKategorien.find(
            (filter) => filter === item.kategorie
          );
        });
      } else {
        return this.$store.getters["meal/getResteMealList"];
      }
    },

    filteredAllergene(meals) {
      const filterAllergene = this.filterAllergene;
      if (filterAllergene && filterAllergene.length > 0) {
        return meals.filter((item) => {
          return !item.allergene.some((r) => filterAllergene.indexOf(r) >= 0);
        });
      } else {
        return meals;
      }
    },
  },
  computed: {
    viewPane: {
      get() {
        return this.$store.getters["getViewPane"];
      },
      set(id) {
        this.$store.commit("SET_VIEW_PANE", id);
      },
    },
    showLieferorteDialog: {
      get() {
        return this.$store.getters["order/isLieferortSelectorShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_LIEFERORTE_SELECTOR", value);
      },
    },
    selectedLieferort: {
      get() {
        return this.$store.getters["order/getSelectedLieferort"];
      },
      set(value) {
        this.$store.commit("order/SET_SELECTED_LIEFERORT", value);
      },
    },
    showOrderPickupResult: {
      get() {
        return this.$store.getters["order/isOrderPickupResultDialogShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_ORDER_PICKUP_RESULT", value);
      },
    },
    filterAllergene: {
      get() {
        return this.$store.getters["meal/getFilterAllergene"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_ALLERGENE", value);
      },
    },
    filterKategorien: {
      get() {
        return this.$store.getters["meal/getFilterKategorien"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_KATEGORIEN", value);
      },
    },
    User() {
      return this.$store.getters["user/getUser"];
    },
    Customer() {
      return this.$store.getters["customer/getCustomer"];
    },
    resteMeals() {
      let meals = this.filteredMeals();
      meals = this.filteredAllergene(meals);
      return meals;
    },
    userLieferorte() {
      let lieferorte = [];
      //1. Prüfen ob die Daten von User und Customer vorhanden sind
      if (this.User && this.Customer) {
        //2a. Wenn es nur einen Lieferort gibt, dann diesen automatisch auswählen (es muss mind. einen Liferort geben)
        if (this.Customer.anlieferorte.length == 1) {
          lieferorte = this.Customer.anlieferorte[0];
          return lieferorte;
        }

        //2b. Wenn es mehrere Lieferorte gibt, prüfe welcher Lieferort in der Abteilung des Benutzers ist
        let userDepartment = this.Customer.essenskosten.find(
          (el) => el.name === this.User.department
        );
        if (!userDepartment || !userDepartment.lieferort) {
          return lieferorte;
        }
        //Durchlaufe alle Lieferorte die in der Abteilung gespeichert sind
        for (let lieferort of userDepartment.lieferorte) {
          //Finde die id des Lieferortes in den Kunden Lieferorten
          let found = this.Customer.anlieferorte.find(
            (el) => el._id === lieferort
          );
          //Wenn Lieferort gefunden wurde, fühe diesen zum Array hinzu
          if (found) {
            lieferorte.push(found);
          }
        }
      }
      //console.log(lieferorte);
      return lieferorte;
    },
  },
};
</script>

<style scoped>
.daycard {
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: #fff !important;
  padding: 4px;
  opacity: 1;
}

.v-dialog--fullscreen {
  overflow: hidden !important;
}
.currentOrder {
  margin-left: 10px;
  font-size: 14px;
  color: green;
}
.scanHeader {
  background-color: green;
  color: white;
  font-size: 1.2em;
}

.mealRow {
  font-size: 16px;
  font-weight: bold;
}

.resultText {
  font-size: 1.7em;
  line-height: 1.7em;
  text-align: center;
}

.errorText {
  font-size: 1.7em;
  line-height: 1.7em;
  text-align: center;
}

.amount {
  font-weight: bold;
  color: green;
}
</style>
