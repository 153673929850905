<template>
  <v-container>
    <v-card v-if="Orders.length == 0">
      <v-card-text class="text-center">
        <h3>Keine Bestellungen vorhanden</h3>
      </v-card-text>
    </v-card>

    <v-row v-for="(order, index) in Orders" :key="index">
      <v-col>
        <v-card @click="loadOrder(order)" v-if="order.days.length > 0">
          <v-card-title>
            Lieferwoche:
            {{ lieferKW(order.days[0].dayDate)[0] }} -
            {{ lieferKW(order.days[0].dayDate)[1] }}
            <v-chip
              class="ml-2"
              small
              color="green"
              text-color="white"
              v-show="isCurrentOrder(order)"
            >
              Aktuelle Bestellung</v-chip
            >
            <v-chip
              class="ml-2"
              small
              color="secondary"
              text-color="white"
              v-show="isCurrentWeek(order)"
            >
              Laufende Woche</v-chip
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row v-if="order.orderMode === 'bulk'">
                <v-col>
                  <h4>Großgebinde Bestellung</h4>
                </v-col>
              </v-row>
              <v-row v-else-if="order.orderMode === 'big'">
                <v-col>
                  <h4>Großbestellung</h4>
                </v-col>
              </v-row>
              <v-row v-else-if="order.orderMode === 'single'">
                <v-col>
                  <h4>Eigene Bestellung</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  Bestellung vom {{ getDate(order.date) }}
                  <br />
                  Bestellende:
                  {{ bestellEnde(order.bestellEnde) }}
                  Uhr<br />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-icon v-show="order.status === 'saved'" color="green"
                    >mdi-check</v-icon
                  >
                  <v-icon v-show="order.status === 'open'"
                    >mdi-lock-open</v-icon
                  >
                </v-col>
                <v-col cols="4" xs="4">
                  Gerichte: {{ totalMeals(order) }}</v-col
                >
                <v-col cols="6">
                  Gesamtpreis: € {{ currencyFormat(orderPrice(order)) }}</v-col
                >
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dlgOrderDetails"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card tile class="fill-height detailOrders" v-if="orderDetail">
        <v-toolbar dark>
          <v-toolbar-title>Bestellungsdetails</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeOrderDialog"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-title>
          <h4 class="mb-4">
            Lieferwoche: {{ lieferKW(orderDetail.days[0].dayDate)[0] }} -
            {{ lieferKW(orderDetail.days[0].dayDate)[1] }}
            <br />(KW: {{ dateKW(orderDetail.days[0].dayDate) }})
          </h4>
          <br />
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6" sm="6"> Status der Bestellung:</v-col>
              <v-col cols="6" sm="6">
                <span v-if="orderDetail.status === 'open'"
                  ><v-icon class="mr-3">mdi-lock-open</v-icon
                  ><v-chip small>Offen</v-chip></span
                >
                <span v-else-if="orderDetail.status === 'saved'"
                  ><v-icon color="green" class="mr-3">mdi-check</v-icon
                  ><v-chip small color="green">Abgeschlossen</v-chip></span
                >
                <span v-else-if="orderDetail.status === 'canceled'"
                  ><v-icon color="red" class="mr-3">mdi-lock-open-remove</v-icon
                  ><v-chip small color="red">Storniert</v-chip></span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Bestellung vom {{ getDate(orderDetail.date) }}
                <br />
                Bestellende:
                {{ bestellEnde(orderDetail.bestellEnde) }}
                Uhr<br />
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row
              fluid
              class="mb-4"
              v-for="(day, index) in orderDetailSortedDays"
              :key="index"
            >
              <v-col>
                <v-card tile>
                  <v-card-title>
                    {{ getDayName(day.day) }} {{ day.day }}
                  </v-card-title>
                  <v-card-text>
                    <div
                      v-for="(item, mealIndex) in day.meals"
                      :key="mealIndex"
                    >
                      <v-row class="mealRow pt-2">
                        <v-col cols="2"> {{ item.totalAmount }}x </v-col>
                        <v-col>
                          {{ item.meal.name }}
                        </v-col>
                        <v-col cols="3" xs="3" class="text-right">
                          {{
                            currencyFormat(
                              inklPreis(item.meal.preis) * item.totalAmount
                            )
                          }}
                          €
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          v-for="(timeSlot, timeIndex) in item.timeSlots"
                          :key="timeIndex"
                        >
                          <v-chip small v-show="timeSlot" class="mr-1">
                            {{ timeSlot }}x - {{ getTimeSlot(timeIndex) }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                  <v-card-text>
                    <v-divider />
                    <v-row>
                      <v-col class="text-right">
                        <div>
                          Tagessumme: €
                          <span class="dayPrice">{{
                            currencyFormat(getDayPrice(day))
                          }}</span>
                        </div>
                        <div v-show="getDayZuschuss(day) > 0.0">
                          abzüglich Firmenzuschuss: €
                          <span class="dayPrice">{{
                            currencyFormat(getDayZuschuss(day))
                          }}</span>
                        </div>

                        <div v-show="getDayZuschuss(day) > 0.0">
                          Aufzuzahlen
                          <span class="dayPrice">{{
                            currencyFormat(
                              Math.abs(getDayPrice(day) - getDayZuschuss(day))
                            )
                          }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeOrderDialog" color="red" outlined
            >Schließen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import sortOrderDays from "@/helpers/orderDaySorted";

export default {
  name: "Orders",
  components: {},
  data: () => ({
    orderDetail: null,
  }),
  methods: {
    bestellEnde(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    getDayName(date) {
      if (!(date instanceof moment)) {
        date = moment(date, "DD.MM.YYYY");
      }
      return moment(date).format("dddd");
    },
    dateKW(date) {
      return moment(date).isoWeek();
    },
    lieferKW(date) {
      let first = moment(date).isoWeekday(1).format("DD.MM.YYYY");
      let last = moment(date).isoWeekday(7).format("DD.MM.YYYY");
      return [first, last];
    },
    getDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getTimeSlot(index) {
      const timeSlot = this.Customer.mahlzeiten[index];
      return `${timeSlot.von} - ${timeSlot.bis}`;
    },
    currencyFormat(value = 0) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    inklPreis(price) {
      let mwst = (110 * price) / 100;
      return mwst;
    },
    getDayPrice(day) {
      let sum = 0;
      for (let index in day.meals) {
        sum +=
          day.meals[index].totalAmount *
          this.inklPreis(day.meals[index].meal.preis);
      }

      return sum;
    },
    getDayZuschuss(day) {
      if (this.firmenzuschuss) {
        if (this.firmenzuschuss.perItem === 0) {
          //Pro Essen

          let timeSlots = [];
          for (let mealIndex in day.meals) {
            let meal = day.meals[mealIndex];
            for (let slotIndex in meal.timeSlots) {
              if (!timeSlots[slotIndex]) {
                timeSlots[slotIndex] = 0;
              }

              timeSlots[slotIndex] +=
                this.inklPreis(meal.meal.preis) * meal.timeSlots[slotIndex];
            }
          }

          let zuschussGesamt = 0.0;
          for (let slotIndex in timeSlots) {
            let zuschuss = 0.0;
            const slotSumme = timeSlots[slotIndex];
            if (this.firmenzuschuss.type === 0) {
              //Betrag
              zuschuss = slotSumme - Number(this.firmenzuschuss.amount);
              if (zuschuss >= 0.0) {
                zuschussGesamt += Number(this.firmenzuschuss.amount);
              } else {
                zuschussGesamt += slotSumme;
              }
            } else if (this.firmenzuschuss.type === 1) {
              //Prozent
              zuschuss = (Number(this.firmenzuschuss.amount) * slotSumme) / 100;
              zuschussGesamt += zuschuss;
            }
          }

          return zuschussGesamt;
        } else if (this.firmenzuschuss.perItem === 1) {
          //Pro Tag
          let zuschuss = 0.0;
          const tagesSumme = this.getDayPrice(day);
          let abzug = 0.0;
          if (this.firmenzuschuss.type === 0) {
            //Betrag
            zuschuss = tagesSumme - Number(this.firmenzuschuss.amount);
            //Wenn Zuschuss weniger ist als die Tagessumme gib den Zuschuss zurück
            if (zuschuss >= 0.0) {
              abzug = Number(this.firmenzuschuss.amount);
            } else {
              //Wenn der Zuschuss mehr ist als die Tagessumme übernehme volle kosten
              abzug = tagesSumme;
            }
          } else if (this.firmenzuschuss.type === 1) {
            //Prozent
            zuschuss = (Number(this.firmenzuschuss.amount) * tagesSumme) / 100;

            abzug = zuschuss;
          }

          return abzug;
        }
      } else {
        return 0;
      }
    },
    totalMeals(item) {
      let sum = 0;
      for (let index in item.days) {
        sum += item.days[index].amount;
      }
      return sum;
    },
    orderPrice(order) {
      let sum = order.prices.totalSumBrutto - order.prices.totalZuschuss;
      if (order.orderMode && order.orderMode !== "single") {
        sum = order.prices.totalSumBrutto;
      }
      if (sum < 0.0) {
        return 0;
      } else {
        return sum;
      }
    },

    loadOrder(order) {
      if (this.isCurrentOrder(order)) {
        this.viewPane = 1;
        return false;
      }
      this.orderDetail = order;
      this.dlgOrderDetails = true;
      // console.log(order);
    },
    closeOrderDialog() {
      this.dlgOrderDetails = false;
      this.orderDetail = null;
    },

    isCurrentOrder(order) {
      if (!this.currentOrder) {
        return false;
      }
      return order._id === this.currentOrder._id;
    },
    isCurrentWeek(order) {
      if (!this.actualOrder) {
        return false;
      }
      let found = order.days.find(
        (el) => el.day === moment().format("DD.MM.YYYY")
      );
      if (found) {
        return true;
      }
      return false;
    },
  },
  computed: {
    viewPane: {
      get() {
        return this.$store.getters["getViewPane"];
      },
      set(id) {
        this.$store.commit("SET_VIEW_PANE", id);
      },
    },
    Orders() {
      //console.log(this.$store.getters["order/getOrders"]);
      return this.$store.getters["order/getOrders"];
    },
    User() {
      return this.$store.getters["user/getUser"];
    },
    Customer() {
      return this.$store.getters["customer/getCustomer"];
    },

    currentOrder() {
      return this.$store.getters["order/getOrder"];
    },

    actualOrder() {
      return this.$store.getters["order/getActualOrder"];
    },

    orderDays() {
      return this.$store.getters["order/getOrderDays"];
    },
    daySortedOrder() {
      return this.$store.getters["order/daySortedOrder"];
    },
    orderDetailSortedDays() {
      return sortOrderDays.sortOrderDays(this.orderDetail);
    },
    orderDetailKW() {
      return moment(this.orderDetail.date).isoWeek();
    },

    lastOrderSave() {
      return moment(this.currentOrder.modifiedTS).format("DD.MM.YYYY HH:mm:ss");
    },

    dlgOrderDetails: {
      get() {
        return this.$store.getters["order/isOrderDetailsShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_ORDER_DETAILS", value);
      },
    },
  },

  update() {},
};
</script>

<style scoped>
.v-card__title {
  font-size: 1rem !important;
}
.currentOrder {
  margin-left: 10px;
  font-size: 14px;
  color: green;
}

.detailOrders {
  height: 100vh !important;
  min-height: 100vh !important;
}
</style>
