<template>
  <v-footer app dark fixed padless>
    <v-card v-if="customer" flat tile dark width="100%">
      <v-card-text class="pa-2 white--text" align="center">
        <v-dialog
          v-model="isExtendedFilterShown"
          fullscreen
          transition="dialog-bottom-transition"
        >
          <v-card tile class="fill-height">
            <v-toolbar dark>
              <v-toolbar-title>Gerichte filtern</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeFilterDialog"
                ><v-icon>mdi-check</v-icon></v-btn
              >
            </v-toolbar>
            <v-container>
              <v-expansion-panels multiple v-model="openFilters">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Kategorien
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div :key="1" v-show="viewPane == 1" class="mealFilter">
                      <template
                        v-for="(kategorie, index) of filterSubKategorien"
                      >
                        <div v-if="kategorie.visible" :key="index">
                          <h5>{{ kategorie.kategorie }}</h5>

                          <v-chip
                            v-for="(
                              subkategorie, subIndex
                            ) of kategorie.subKategorien"
                            :key="subIndex"
                            small
                            dark
                            :disabled="disableFilters"
                            :color="subkategorie.active ? '#C25443' : 'grey'"
                            @click="
                              updateKategorienFilter(kategorie, subkategorie)
                            "
                            class="ma-2"
                            >{{ subkategorie.name }}
                          </v-chip>
                        </div>
                      </template>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Allergene ausschließen
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-spacer></v-spacer>
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('A')"
                      :color="isAllergenFilter('A') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Getreide.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Getreide (A)</v-chip
                    >
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('B')"
                      :color="isAllergenFilter('B') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Krebstiere.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Krebstiere (B)</v-chip
                    >

                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('C')"
                      :color="isAllergenFilter('C') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Eier.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Eier (C)</v-chip
                    >
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('D')"
                      :color="isAllergenFilter('D') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Fisch.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Fisch (D)</v-chip
                    >

                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('E')"
                      :color="isAllergenFilter('E') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Erdnüsse.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Erdnüsse (E)</v-chip
                    >
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('F')"
                      :color="isAllergenFilter('F') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Soja.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Sojabohnen (F)</v-chip
                    >
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('G')"
                      :color="isAllergenFilter('G') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Milch.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Milch (G)</v-chip
                    >
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('H')"
                      :color="isAllergenFilter('H') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Schalenfrüchte.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Schalenfrüchte (H)</v-chip
                    >
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('L')"
                      :color="isAllergenFilter('L') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Sellerie.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Sellerie (L)</v-chip
                    >
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('M')"
                      :color="isAllergenFilter('M') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Senf.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Senf (M)</v-chip
                    >
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('N')"
                      :color="isAllergenFilter('N') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Sesam.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Sesamen (N)</v-chip
                    >
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('O')"
                      :color="isAllergenFilter('O') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Sulfite.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Sulfite (O)</v-chip
                    >
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('P')"
                      :color="isAllergenFilter('P') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Lupinen.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Lupinen (P)</v-chip
                    >
                    <v-chip
                      small
                      dark
                      @click="toggleAllergenFilter('R')"
                      :color="isAllergenFilter('R') ? 'accent' : ''"
                      class="ma-2"
                    >
                      <v-img
                        src="@/assets/icons/Weichtiere.png"
                        height="18px"
                        width="18px"
                        class="mr-2"
                        contain
                      />
                      Weichtiere (R)</v-chip
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </v-card>
        </v-dialog>
        <div class="copyright">
          {{ new Date().getFullYear() }} - {{ copyright }}
        </div>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: function () {
    return {
      name: "FooterOrder",
      openFilters: [0, 1],
    };
  },
  props: {
    copyright: {
      type: String,
      default: "Anewo",
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleExtendedFilter() {
      this.$store.commit(
        "meal/SHOW_EXTENDED_FILTER",
        !this.isExtendedFilterShown
      );
    },
    closeFilterDialog() {
      this.$store.commit("meal/SHOW_EXTENDED_FILTER", false);
    },

    isAllergenFilter(allergen) {
      return !!this.filterAllergene.find(
        (item) => String(item).toLowerCase() === String(allergen).toLowerCase()
      );
    },

    updateKategorienFilter(kategorie, subKategorie) {
      let activeFilters = 0;
      for (let sub of kategorie.subKategorien) {
        //Prüfe ob Subkategorie Alle ist
        if (subKategorie.name === "Alle") {
          if (sub.name === "Alle") {
            sub.active = !sub.active;
          } else {
            sub.active = false;
          }
        } else {
          if (sub.name === "Alle") {
            sub.active = false;
          } else if (sub.name === subKategorie.name) {
            sub.active = !subKategorie.active;
          }
        }
        if (sub.active) {
          activeFilters++;
        }
      }
      if (activeFilters === 0 && subKategorie.name !== "Alle") {
        kategorie.subKategorien[0].active = true;
      }
    },
    toggleAllergenFilter(allergen) {
      const filter = this.filterAllergene.find(
        (item) => String(item).toUpperCase() === String(allergen).toUpperCase()
      );
      if (filter) {
        this.filterAllergene = this.filterAllergene.filter(
          (item) => item !== allergen
        );
      } else {
        this.filterAllergene.push(allergen);
      }
    },
  },
  computed: {
    viewPane() {
      return this.$store.getters["getViewPane"];
    },
    isExtendedFilterShown() {
      return this.$store.getters["meal/isExtendedFilterShown"];
    },
    filterAllergene: {
      get() {
        return this.$store.getters["meal/getFilterAllergene"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_ALLERGENE", value);
      },
    },
    filterKategorien: {
      get() {
        return this.$store.getters["meal/getFilterKategorien"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_KATEGORIEN", value);
      },
    },

    filterSubKategorien: {
      get() {
        return this.$store.getters["meal/getFilterSubKategorien"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_SUBKATEGORIEN", value);
      },
    },

    customer() {
      return this.$store.getters["customer/getCustomer"];
    },
    user() {
      return this.$store.getters["user/getUser"];
    },

    disableFilters() {
      return !!this.customer.essenskosten.find(
        (el) =>
          el.name === this.user.department &&
          el.limits &&
          el.limits.hiddenCategories.length >= 2
      );
    },

    isVorspeisenHidden() {
      return !!this.customer.essenskosten.find(
        (el) =>
          el.name === this.user.department &&
          el.limits &&
          el.limits.hiddenCategories.find((h) => h === "Vorspeisen")
      );
    },
    isHauptspeisenHidden() {
      return !!this.customer.essenskosten.find(
        (el) =>
          el.name === this.user.department &&
          el.limits &&
          el.limits.hiddenCategories.find((h) => h === "Hauptspeisen")
      );
    },
    isDessertsHidden() {
      return !!this.customer.essenskosten.find(
        (el) =>
          el.name === this.user.department &&
          el.limits &&
          el.limits.hiddenCategories.find((h) => h === "Desserts")
      );
    },
  },
  created() {
    if (this.customer) {
      let department = this.customer.essenskosten.find(
        (el) => el.name === this.user.department
      );
      if (department) {
        let kategorien = [];
        if (!this.isVorspeisenHidden) {
          kategorien.push("Vorspeisen");
        }

        if (!this.isHauptspeisenHidden) {
          kategorien.push("Hauptspeisen");
        }

        if (!this.isDessertsHidden) {
          kategorien.push("Desserts");
        }

        this.filterKategorien = kategorien;
      }
    }
  },
};
</script>

<style scoped>
#logo {
  display: inline-block;
  min-width: 100px;
  max-height: 14px;
  width: 150px;
}

#datafieberlink {
  vertical-align: middle;
}

.copyright {
  margin-top: 10px;
}
</style>
