<template>
  <v-container fluid class="ma-0 pa-0">
    <v-window v-model="orderPane" class="fill-height" touchless>
      <v-window-item>
        <MealList :items="Meals" />

        <v-slide-y-reverse-transition>
          <FooterOrder />
        </v-slide-y-reverse-transition>
      </v-window-item>
      <v-window-item>
        <OrderOverview />
        <v-slide-y-reverse-transition>
          <FooterOverview />
        </v-slide-y-reverse-transition>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
// @ is an alias to /src
import MealList from "@/components/meals/MealList";
import OrderOverview from "@/views/OrderOverview";
import FooterOrder from "@/components/FooterOrder";
import FooterOverview from "@/components/FooterOverview";
import moment from "moment";

export default {
  name: "Order",
  components: {
    MealList,
    OrderOverview,
    FooterOrder,
    FooterOverview,
  },
  data: () => ({
    day: null,
  }),
  methods: {
    loadMeals(date) {
      this.$store.commit("SET_APP_LOADING", true);
      this.$store
        .dispatch("meal/fetchMealList", { startdate: date, enddate: date })
        .then(() => {
          this.$store.commit("order/INIT_ORDER", {
            customer: this.Customer,
            user: this.$store.getters["user/getUser"],
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("SET_APP_LOADING", false);
        });
    },

    checkCategoryIsHidden(kategorie) {
      if (this.userDepartment) {
        if (
          this.userDepartment.limits?.hiddenCategories?.indexOf(kategorie) >= 0
        ) {
          return true;
        }
      }

      return false;
    },

    filteredMeals() {
      let meals = this.$store.getters["meal/getMealList"];
      let filteredItems = [];
      for (let kategorie of this.filterSubKategorien) {
        //console.log(kategorie, this.checkCategoryIsHidden(kategorie.kategorie));
        if (this.checkCategoryIsHidden(kategorie.kategorie)) {
          kategorie.visible = false;
          continue;
        }

        let elements = [];
        //Wenn der Eintrag "Alle" ausgewählt ist filtere nicht
        if (kategorie.subKategorien[0].active) {
          elements = meals.filter(
            (meal) => meal.kategorie === kategorie.kategorie
          );
        } else {
          const activeFilters = kategorie.subKategorien.filter(
            (item) => item.active
          );
          // console.log("Active", activeFilters);
          elements = meals.filter(
            (meal) =>
              meal.kategorie === kategorie.kategorie &&
              activeFilters.find((filter) => filter.name === meal.subkategorie)
          );
        }
        filteredItems = [...filteredItems, ...elements];
      }

      return filteredItems;
    },

    filteredAllergene(meals) {
      const filterAllergene = this.filterAllergene;
      if (filterAllergene && filterAllergene.length > 0) {
        return meals.filter((item) => {
          return !item.allergene.some((r) => filterAllergene.indexOf(r) >= 0);
        });
      } else {
        return meals;
      }
    },
  },
  computed: {
    bestellEnde() {
      if (this.Customer) {
        const bestellEndeTag = Number.parseInt(this.Customer.bestellendeTag);
        const bestellTimeParts = this.Customer.bestellendeZeit.split(":");
        const bestellEnde = moment()
          .isoWeekday(bestellEndeTag)
          .hour(bestellTimeParts[0])
          .minute(bestellTimeParts[1])
          .second(0);

        return bestellEnde.format("DD.MM.YYYY HH:mm") + " Uhr";
      }
      return "";
    },
    orderZeitraum() {
      const current = moment();
      const nextWeek = moment().isoWeek(current.isoWeek() + 1);
      const firstKWDay = moment(nextWeek).isoWeekday(1);
      const lastKWDay = moment(nextWeek).isoWeekday(7);
      return (
        firstKWDay.format("DD.MM.YYYY") +
        " bis " +
        lastKWDay.format("DD.MM.YYYY") +
        " ( KW: " +
        nextWeek.isoWeek() +
        " )"
      );
    },
    Customer: {
      get() {
        return this.$store.getters["customer/getCustomer"];
      },
      set(value) {
        this.$store.commit("customer/SET_CUSTOMER", value);
      },
    },
    orderPane: {
      get() {
        return this.$store.getters["getOrderPane"];
      },
      set(id) {
        this.$store.commit("SET_ORDER_PANE", id);
      },
    },
    viewPane() {
      {
        return this.$store.getters["getViewPane"];
      }
    },
    User() {
      return this.$store.getters["user/getUser"];
    },

    filterKategorien: {
      get() {
        return this.$store.getters["meal/getFilterKategorien"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_KATEGORIEN", value);
      },
    },

    filterSubKategorien: {
      get() {
        return this.$store.getters["meal/getFilterSubKategorien"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_SUBKATEGORIEN", value);
      },
    },

    filterAllergene: {
      get() {
        return this.$store.getters["meal/getFilterAllergene"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_ALLERGENE", value);
      },
    },
    filterText: {
      get() {
        return this.$store.getters["meal/getMealSearch"];
      },
      set(meal) {
        this.$store.commit("meal/SET_MEAL_SEARCH", meal);
      },
    },

    userDepartment() {
      let department = null;
      if (this.Customer && this.User) {
        department = this.Customer.essenskosten.find(
          (el) => el.name === this.User.department
        );
      }
      return department;
    },

    Meals() {
      let meals = this.filteredMeals();

      /* Filtere die Gerichte nach Grossgebinde Gerichte wenn eine Grossgebinde Bestellung gemacht wird (darf nur der Kunden Admin machen),
       * oder wenn das Flag "isShowOnlyGrossgebindeMeals" gesetzt ist welches vom Server anhand der Abteilung und dem Flag showOnlyGrossgebindeMeals
       * gesetzt wird
       */
      /* if (
        this.$store.getters["meal/isShowOnlyGrossgebindeMeals"] &&
        !this.$store.getters["order/isGrossBestellungOrder"]
      )*/
      if (
        this.userDepartment.showOnlyGrossgebindeMeals === true &&
        this.orderMode === "single"
      ) {
        meals = meals.filter((el) => el.grossgebinde);
        meals = meals.filter(
          (el) => el.orderDays.indexOf(this.selectedDay.isoWeekday()) >= 0
        );
      }

      meals = this.filteredAllergene(meals);
      if (this.filterText && this.filterText.length > 0) {
        meals = meals.filter(
          (item) => item.name.search(new RegExp(this.filterText, "i")) >= 0
        );
      }
      return meals;
    },

    orderMode: {
      get() {
        return this.$store.getters["order/getOrderMode"];
      },
      set(value) {
        this.$store.commit("order/SET_ORDER_MODE", value);
      },
    },

    selectedDay() {
      return this.$store.getters["order/getSelectedDay"];
    },
  },

  created() {
    moment.locale("de-de");
  },
};
</script>

<style scoped>
.cardOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
.cardTitle {
  text-align: center;
  margin: 0px auto;
  font-size: 1.3em;
  font-weight: bold;
  text-transform: uppercase;
}

.fixed-bar {
  position: fixed;
  width: 100%;
  top: 64px;
  z-index: 2;
  height: 40px;
}
.v-main__wrap {
  padding-top: 100px !important;
}
</style>
