<template>
  <v-card tile class="mealCard" v-if="Meal">
    <v-img
      v-if="(Meal && Meal.bilder.length == 0) || Meal.bilder.length == 1"
      class="previewImage"
      :src="imageUrl"
      :aspect-ratio="16 / 9"
      width="100%"
    >
      <div class="mealTitle">{{ Meal.name }}</div>
    </v-img>
    <v-carousel
      touchless
      class="mealImageSlider"
      v-else
      cycle
      hide-delimter-background
      hide-delimiters
      :show-arrows="false"
      interval="4000"
    >
      <v-carousel-item v-for="(image, index) in Meal.bilder" :key="index">
        <v-img
          :src="image.data"
          :aspect-ratio="16 / 9"
          width="100%"
          class="previewImage"
        >
          <div class="mealTitle">{{ Meal.name }}</div>
        </v-img>
      </v-carousel-item>
    </v-carousel>
    <v-container>
      <v-row>
        <v-col sm="6">
          <v-chip class="ma-2" :color="kategorieFarbe()">{{
            Meal.kategorie
          }}</v-chip>
        </v-col>
        <v-col sm="6" class="text-right">
          <div class="ma-2 pr-3">
            Preis inkl.: € {{ currencyFormat(inklPreis(Meal.preis)) }}
          </div>
        </v-col>
      </v-row>
      <v-row v-if="userLieferorte.length > 1">
        <v-col>
          <v-alert outlined text dense color="green">
            Lieferort auswählen:
            <v-select
              dense
              :items="userLieferorte"
              v-model="lieferort"
              color="secondary"
            />
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="Meal.count">
      <v-row align="center">
        <v-col>
          <Amount
            label="Menge"
            unit="Stk."
            :min="0"
            :max="mealCount"
            @increment="incrementAmount"
            @decrement="decrementAmount"
            v-model="restAmount"
          />
        </v-col>
        <v-col>
          Summe: € {{ currencyFormat(inklPreis(Meal.preis * restAmount)) }}
        </v-col>
      </v-row>
      <v-row v-if="lieferort !== 'all'">
        <v-col>
          <v-btn
            :disabled="restAmount == 0"
            block
            class="white--text"
            color="green"
            @click="pickupMeal()"
            ><v-icon>mdi-check</v-icon> Jetzt abholen & essen
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="Customer && Customer.roboters.length > 0">
        <v-col>
          <v-btn
            :disabled="restAmount == 0"
            color="secondary"
            block
            class="white--text"
            @click="showQRCode()"
            >QR Code anzeigen & abholen</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else tile flat style="display: inline-block">
      <v-row v-if="orderMode === 'single'">
        <v-col v-for="(zeit, index) in Customer.mahlzeiten" :key="index">
          <MealTimeSelector
            v-if="zeit.active"
            :zeit="zeit"
            :keyname="String(index)"
            :max="10"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col v-for="(zeit, index) in Customer.mahlzeiten" :key="index">
          <MealTimeSelectorGrossgebinde
            v-if="zeit.active"
            :zeit="zeit"
            :keyname="String(index)"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid style="background-color: white">
      <v-row class="mt-1">
        <div v-show="Meal.glutenfrei" class="propImage">
          <v-img
            src="@/assets/icons/Glutenfrei.png"
            width="48px"
            height="48px"
          />
        </div>
        <div v-show="Meal.laktosefrei" class="propImage">
          <v-img
            src="@/assets/icons/Laktosefrei.png"
            width="48px"
            height="48px"
          />
        </div>
        <div v-show="Meal.vegetarisch" class="propImage">
          <v-img
            v-show="Meal.vegetarisch"
            src="@/assets/icons/Vegetarisch.png"
            width="48px"
            height="48px"
          />
        </div>
        <div v-show="Meal.schweinefleischfrei" class="propImage">
          <v-img
            src="@/assets/icons/Schweinefleischfrei.png"
            width="48px"
            height="48px"
          />
        </div>
      </v-row>

      <v-row class="">
        <v-col v-show="Meal.allergene.length == 0">
          <h4>Keine Allergene vorhanden</h4>
        </v-col>
        <span v-for="(el, index) in Meal.allergene" :key="index">
          <v-chip small class="ma-2" text-color="white" color="accent">
            <v-img
              v-if="el !== ''"
              :src="allergenIcon(el)"
              eager
              class="mr-2"
              contain
              width="20px"
              height="20px"
            />
            {{ getAllergenName(el) }}
          </v-chip>
        </span>
      </v-row>
      <v-row v-show="Meal.beschreibung">
        <v-col>
          <h4>Beschreibung:</h4>
          <div class="pa-2 mealDesc">{{ Meal.beschreibung }}</div>
        </v-col>
      </v-row>
      <v-row v-show="Meal.zutatenliste">
        <v-col>
          <h4>Zutatenliste:</h4>
          <div class="pa-2 mealDesc">{{ Meal.zutatenliste }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-rating
            empty-icon="mdi-chef-hat"
            full-icon="mdi-chef-hat"
            readonly
            background-color="grey darken-1"
            color="green"
            length="5"
            small
            :value="3"
          ></v-rating>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="qrCodeDialog" fullscreen>
      <v-toolbar dark>
        <v-toolbar-title>QR Code abscanne</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeQrCodeDialog()"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card class="fill-height">
        <v-card tile class="scanHeader">
          <v-card-text class="scanHeader"
            >Bitte halte den QR Code an den Scanner</v-card-text
          >
        </v-card>
        <v-container v-if="resteOrder">
          <v-row justiy="center">
            <v-col class="text-center">
              <h2>{{ resteOrder.days.meal.name }}</h2>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col class="text-center">
              <v-img
                :src="resteOrder.days.qrCode"
                style="max-width: 300px; margin: 0 auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense justify="center" align="center"
              ><v-chip
                label
                outlined
                :color="resteOrder.days.pickup ? 'green' : 'orange'"
              >
                Status: {{ pickupStatus(resteOrder.days) }}</v-chip
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Amount from "@/components/Amount";
import MealTimeSelector from "./MealTimeSelector";
import MealTimeSelectorGrossgebinde from "./MealTimeSelectorGrossgebinde.vue";
export default {
  components: { MealTimeSelector, MealTimeSelectorGrossgebinde, Amount },
  data: () => ({
    restAmount: 0,
    qrCodeDialog: false,
    resteOrder: null,
  }),
  methods: {
    incrementAmount(amount) {
      this.restAmount = amount;
    },
    decrementAmount(amount) {
      this.restAmount = amount;
    },
    allergenIcon(allergen) {
      let src = "";
      const kuerzel = String(allergen).toUpperCase().trim();
      if (kuerzel == "A") {
        src = "Getreide.png";
      } else if (kuerzel == "B") {
        src = "Krebstiere.png";
      } else if (kuerzel == "C") {
        src = "Eier.png";
      } else if (kuerzel == "D") {
        src = "Fisch.png";
      } else if (kuerzel == "E") {
        src = "Erdnüsse.png";
      } else if (kuerzel == "F") {
        src = "Soja.png";
      } else if (kuerzel == "G") {
        src = "Milch.png";
      } else if (kuerzel == "H") {
        src = "Schalenfrüchte.png";
      } else if (kuerzel == "L") {
        src = "Sellerie.png";
      } else if (kuerzel == "M") {
        src = "Senf.png";
      } else if (kuerzel == "N") {
        src = "Sesam.png";
      } else if (kuerzel == "O") {
        src = "Sulfite.png";
      } else if (kuerzel == "P") {
        src = "Lupinen.png";
      } else if (kuerzel == "R") {
        src = "Weichtiere.png";
      }
      if (src === "") {
        return "";
      }

      return require("@/assets/icons/" + src);
    },

    pickupStatus(item) {
      if (item.check) {
        return "Gericht überprüft, in Auslieferung";
      } else if (item.pickup) {
        return "Ausgeliefert";
      } else {
        return "Wartend";
      }
    },

    kategorieFarbe() {
      if (this.Meal.kategorie == "Vorspeisen") {
        return "red lighten-2";
      } else if (this.Meal.kategorie == "Hauptspeisen") {
        return "green lighten-2";
      } else if (this.Meal.kategorie == "Desserts") {
        return "orange lighten-2";
      }
    },
    currencyFormat(value = 0) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    getMealAmount() {
      try {
        const dayOrders =
          this.Order.days[this.selectedDay.format("DD-MM-YYYY").toString()]
            .times[this.keyname].meals;

        // return timeOrders.meals;
        this.amount = dayOrders[String(this.Meal.gerichtnr)].amount || 0;
      } catch (error) {
        //console.log(error);
        this.amount = 0;
      }
    },
    inklPreis(price) {
      let mwst = (110 * price) / 100;
      return mwst;
    },
    getAllergenName(value) {
      const allergen = this.Allergene.find(
        (item) =>
          String(item.value).toLowerCase() === String(value).toLowerCase()
      );
      if (allergen) {
        return allergen.text;
      }
      return "";
    },
    closeQrCodeDialog() {
      this.qrCodeDialog = false;
      this.$emit("qr-code-dialog-closed", true);
    },
    showQRCode() {
      this.$store
        .dispatch("order/getNextUnpickedOrder", {
          meal: this.Meal,
          amount: this.restAmount,
        })
        .then((result) => {
          this.qrCodeDialog = true;
          this.resteOrder = result;
          //TODO: pickup status mit eigenem endpoint abfragen
          this.$store.commit(
            "SET_PICKUP_UPDATE_INTERVAL",
            setInterval(this.updatePickupStatus, 3000)
          );
        });
    },
    pickupMeal() {
      if (this.Meal.count) {
        this.$emit("pickupMeal", { meal: this.Meal, amount: this.restAmount });
      }
    },
  },
  computed: {
    Customer() {
      return this.$store.getters["customer/getCustomer"];
    },
    Order() {
      return this.$store.getters["order/getOrder"];
    },
    orderMode() {
      return this.$store.getters["order/getOrderMode"];
    },
    Meal() {
      return this.$store.getters["meal/getCurrent"];
    },
    mealCount() {
      let count = this.Meal.count;

      return count;
    },
    grossgebindeLieferorte() {
      let lieferorte = this.Customer.anlieferorte.filter(
        (el) => el.grossgebinde && el.active
      );
      let listItems = [];
      for (let item of lieferorte) {
        listItems.push({ text: item.standort, value: item._id });
      }
      return listItems;
    },
    userLieferorte() {
      if (this.orderMode === "bulk") {
        return this.grossgebindeLieferorte;
      }

      let listItems = [];
      for (let item of this.$store.getters["user/getUserLieferorte"]) {
        listItems.push({ text: item.standort, value: item._id });
      }
      return listItems;
    },
    lieferort: {
      get() {
        let ort = this.$store.getters["order/getSelectedLieferort"];
        if (
          this.orderMode === "bulk" &&
          ort == "all" &&
          this.userLieferorte.length > 0
        ) {
          ort = this.grossgebindeLieferorte[0].value;
        } else if (
          this.order !== "bulk" &&
          ort == "all" &&
          this.userLieferorte.length > 0
        ) {
          ort = this.userLieferorte[0].value;
        }
        this.$store.commit("order/SET_SELECTED_LIEFERORT", ort);
        return ort;
      },
      set(id) {
        this.$store.commit("order/SET_SELECTED_LIEFERORT", id);
      },
    },

    Allergene() {
      return this.$store.getters["meal/getAllergene"];
    },
    imageUrl() {
      if (!this.Meal.bilder || this.Meal.bilder.length == 0) {
        if (this.Meal.kategorie == "Vorspeisen")
          return require("@/assets/vorspeise.jpg");
        else if (this.Meal.kategorie == "Hauptspeisen")
          return require("@/assets/hauptspeise.jpg");
        else if (this.Meal.kategorie == "Desserts")
          return require("@/assets/dessert.jpg");
        else return require("@/assets/bg1.jpg");
      }

      return this.Meal.bilder[0].data;
    },

    categoryClass() {
      return "category" + this.Meal.kategorie;
    },
  },
  watch: {
    Meal(val) {
      //Wenn item ein Gericht der restekarte ist, setze die Menge wieder auf 0
      if (val && val.count) {
        this.restAmount = 0;
      }
    },
  },

  mounted() {},
};
</script>

<style scoped>
.previewImage {
  max-height: 150px;
  height: 150px;
}

.mealImageSlider {
  max-height: 150px;
  height: 150px;
  /*background-color: rgba(0, 0, 0, 0.5);*/
}

.mealCard {
  /*background-color: rgba(220, 220, 220, 1) !important;*/
  background-color: #fff !important;
  color: black;
  height: 100vh;
}

.mealTitle {
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: #fff;
  padding: 4px;
  opacity: 1;
}

.mealDesc {
  font-size: 1.2em;
  color: #c25443;
}

.propImage {
  display: inline-block;
  margin-left: 10px;
}
</style>
