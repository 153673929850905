<template>
  <v-main class="pb-0 fill-height">
    <v-window v-if="User" v-model="viewPane" touchless class="fill-height">
      <v-window-item>
        <v-card tile v-if="User">
          <v-card-title>
            Willkommen {{ User.firstname }}

            <v-spacer></v-spacer>
            <v-icon class="mr-2" @click="confirmPasswordChange"
              >mdi-account-key</v-icon
            ></v-card-title
          >
        </v-card>
        <v-container class="mb-12">
          <v-row>
            <v-spacer></v-spacer>
            <v-fade-transition
              v-if="
                Customer &&
                userDepartment &&
                userDepartment.showRestekarte !== 'only'
              "
            >
              <v-col cols="12" xs="12" sm="6">
                <v-card class="ma-3" @click="loadOrderPane">
                  <v-card-text>
                    <v-img
                      src="@/assets/makeorder.jpg"
                      :aspect-ratio="16 / 9"
                      width="100%"
                      alt="Essen bestellen"
                    >
                      <div class="cardOverlay d-flex align-center">
                        <div class="cardTitle">
                          <span v-if="User.canOrderGrossbestellung">Mein </span
                          >Essen bestellen
                        </div>
                      </div>
                    </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-fade-transition>

            <v-fade-transition v-if="User.canOrderGrossbestellung">
              <v-col cols="12" xs="12" sm="6">
                <v-card class="ma-3" @click="loadBigOrderPane">
                  <v-card-text>
                    <v-img
                      src="@/assets/grossbestellung.jpg"
                      :aspect-ratio="16 / 9"
                      width="100%"
                      alt="Großbestellung bestellen"
                    >
                      <div class="cardOverlay d-flex align-center">
                        <div class="cardTitle">Großbestellung bestellen</div>
                      </div>
                    </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-fade-transition>

            <v-fade-transition
              v-if="
                Customer &&
                userDepartment &&
                userDepartment.showRestekarte !== 'not'
              "
            >
              <v-col cols="12" xs="12" sm="6">
                <v-card class="ma-3" @click="loadRestekartePane">
                  <v-card-text>
                    <v-img
                      src="@/assets/restekarte.jpg"
                      :aspect-ratio="16 / 9"
                      width="100%"
                      alt="Verfügbare Essen anzeigen"
                    >
                      <div class="cardOverlay d-flex align-center">
                        <div class="cardTitle">Verfügbare Essen anzeigen</div>
                      </div>
                    </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-fade-transition>

            <v-fade-transition
              v-if="
                Customer &&
                userDepartment &&
                userDepartment.showRestekarte !== 'only'
              "
            >
              <v-col cols="12" xs="12" sm="6">
                <v-card class="ma-3" @click="loadPickup">
                  <v-card-text>
                    <v-img
                      src="@/assets/makeorder.jpg"
                      :aspect-ratio="16 / 9"
                      width="100%"
                      alt="Essen abholen"
                    >
                      <div class="cardOverlay d-flex align-center">
                        <div class="cardTitle">Essen abholen</div>
                      </div>
                    </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-fade-transition>
            <v-fade-transition
              v-if="
                Customer &&
                userDepartment &&
                userDepartment.showRestekarte !== 'only'
              "
            >
              <v-col cols="12" xs="12" sm="6">
                <v-card class="ma-3" @click="loadOrders">
                  <v-card-text>
                    <v-img
                      src="@/assets/myorders.jpg"
                      :aspect-ratio="16 / 9"
                      width="100%"
                      alt="Meine Bestellungen"
                    >
                      <div class="cardOverlay d-flex align-center">
                        <div class="cardTitle">Meine Bestellungen</div>
                      </div>
                    </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-fade-transition>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-window-item>
      <v-window-item>
        <Order :items="Meals" />
      </v-window-item>
      <v-window-item>
        <v-card tile>
          <v-card-title> Essen Abholen </v-card-title>
          <v-card-text>
            <div>QR Code einscannen und Essen abholen.</div>
          </v-card-text>
        </v-card>
        <Pickup />
      </v-window-item>
      <v-window-item>
        <v-card tile>
          <v-card-title> Meine Bestellungen </v-card-title>
        </v-card>
        <Orders />
      </v-window-item>
      <v-window-item>
        <v-card tile>
          <v-card-title> Verfügbare Essen </v-card-title>
          <v-card-text>
            <div>
              Wähle ein Essen aus und scanne den QR Code auf der Verpackung.
            </div>
            <div
              v-if="
                selectedLieferort &&
                selectedLieferort !== 'all' &&
                userLieferorte.length > 1
              "
              class="mt-2"
            >
              Dein aktueller Lieferort:
              <span style="font-weight: bold">{{
                lieferortName(selectedLieferort)
              }}</span>
              <v-btn
                class="text--white mt-2"
                color="secondary"
                block
                @click="showLieferortSelectorDialog"
                >Lieferort ändern</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
        <Restekarte />
      </v-window-item>
    </v-window>
    <v-footer padless dark absolute v-if="viewPane == 0">
      <v-col class="text-center"
        ><div class="version mb-1">Version {{ appVersion }}</div></v-col
      >
    </v-footer>
    <v-dialog
      v-model="dlgConfirmPasswordChange"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card tile class="fill-height">
        <v-card-title>Passwort ändern?</v-card-title>
        <v-card-text>
          <h4 class="mb-4">Hier können Sie Ihr Passwort ändern.</h4>
          <v-form
            name="pwchangeform"
            @submit.prevent="login"
            v-model="pwchangeformValid"
          >
            <v-text-field
              id="password"
              label="Password"
              name="password"
              autocomplete="new-password"
              prepend-icon="mdi-lock"
              :type="passwordVisible1"
              v-model="password"
              :rules="[rules.required, rules.min]"
              :append-icon="passwordIcon1"
              @click:append="showPassword1"
            />

            <v-text-field
              id="passwordconfirm"
              label="Password bestätigen"
              name="passwordconfirm"
              prepend-icon="mdi-lock"
              :type="passwordVisible2"
              v-model="passwordconfirm"
              :rules="[rules.required, passwordConfirmationRule]"
              :append-icon="passwordIcon2"
              @click:append="showPassword2"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            outlined
            :disabled="!pwchangeformValid"
            @click="changePassword"
            >JA, PASSWORT ÄNDERN</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="dlgConfirmPasswordChange = false"
            color="error"
            outlined
            >ABBRECHEN</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
// @ is an alias to /src
import Order from "@/views/Order";
import Orders from "@/views/Orders";
import Pickup from "@/views/Pickup";
import Restekarte from "@/views/Restekarte";
import moment from "moment";
import UserService from "@/services/UserService";

export default {
  name: "Home",
  components: { Order, Orders, Pickup, Restekarte },
  data: () => ({
    workDays: [],
    dlgConfirmPasswordChange: false,
    passwordVisible1: "password",
    passwordIcon1: "mdi-eye-outline",
    passwordVisible2: "password",
    passwordIcon2: "mdi-eye-outline",
    password: "",
    passwordconfirm: "",
    pwchangeformValid: false,
    rules: {
      required: (value) => !!value || "Dieses Feld wird benötigt!.",
      min: (v) => v.length >= 8 || "mind. 8 Zeichen",
      eq8: (v) => v.length == 8 || "Bitte den 8-stelligen Code eingeben",
      email: (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "Gib eine gültige Email Adresse an",
    },
  }),
  methods: {
    showPassword1() {
      if (this.passwordVisible1 === "password") {
        this.passwordVisible1 = "text";
        this.passwordIcon1 = "mdi-eye-off-outline";
      } else {
        this.passwordVisible1 = "password";
        this.passwordIcon1 = "mdi-eye-outline";
      }
    },
    showPassword2() {
      if (this.passwordVisible2 === "password") {
        this.passwordVisible2 = "text";
        this.passwordIcon2 = "mdi-eye-off-outline";
      } else {
        this.passwordVisible2 = "password";
        this.passwordIcon2 = "mdi-eye-outline";
      }
    },
    confirmPasswordChange() {
      this.password = "";
      this.passwordconfirm = "";
      this.dlgConfirmPasswordChange = true;
    },
    changePassword() {
      alert("change");
      this.dlgConfirmPasswordChange = false;
      UserService.saveUserPassword(this.User._id, this.password)
        .then((result) => {
          console.log(result);
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Gespeichert",
            text: "Dein Kennwort ist nun geändert",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: "Das Passwort konnte leider nicht gespeichert werden",
          });
          console.log(error);
        });
    },
    loadOrderPane() {
      this.orderMode = "single";
      this.$store.commit("order/SET_GROSSGEBINDE_ORDER", false);
      this.$store.commit("order/SET_GROSSBESTELLUNG_ORDER", false);
      this.initLoadOrder();
      this.viewPane = 1;
      this.$store.commit("order/TOGGLE_DAY_SELECTOR", true);
      this.$store.commit("meal/SET_MEAL", null);
    },
    loadBigOrderPane() {
      this.orderMode = "big";
      this.$store.commit("order/SET_GROSSBESTELLUNG_ORDER", true);
      this.$store.commit("order/SET_GROSSGEBINDE_ORDER", false);
      this.initLoadOrder();
      this.viewPane = 1;
      this.$store.commit("order/TOGGLE_DAY_SELECTOR", true);
      this.$store.commit("meal/SET_MEAL", null);
    },
    loadBulkOrderPane() {
      this.orderMode = "bulk";
      this.$store.commit("order/SET_GROSSGEBINDE_ORDER", true);
      this.$store.commit("order/SET_GROSSBESTELLUNG_ORDER", false);
      this.initLoadOrder();
      this.viewPane = 1;
      this.$store.commit("order/TOGGLE_DAY_SELECTOR", true);
      this.$store.commit("meal/SET_MEAL", null);
    },
    loadRestekartePane() {
      this.viewPane = 4;
      this.$store.commit("meal/SET_MEAL", null);
      if (
        this.Customer &&
        this.$store.getters["user/getUserLieferorte"].length > 1
      ) {
        this.$store.commit("order/TOGGLE_LIEFERORTE_SELECTOR", true);
      } else {
        this.$store.commit("SET_APP_LOADING", true);

        this.$store
          .dispatch(
            "meal/fetchUnpickedMeals",
            this.$store.getters["order/getSelectedLieferort"]
          )
          .then(() => {})
          .catch((error) => {
            console.log(error);
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Abfragefehler",
              text: "Die verfügbaren Gerichte konnten nicht abgefragt werden",
            });
          })
          .finally(() => {
            this.$store.commit("SET_APP_LOADING", false);
          });
      }
      /* this.$store.dispatch("meal/fetchRestekarteMeals").then(() => {
        this.viewPane = 4;
      });*/
    },

    initLoadOrder() {
      this.$store
        .dispatch("order/initOrder", {
          user: this.User,
          customer: this.Customer,
          orderDates: this.$store.getters["order/getOrderDays"],
        })
        .then(() => {
          //console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadPickup() {
      this.$store
        .dispatch("order/fetchActualOrder", this.User._id)
        .then(() => {
          this.viewPane = 2;
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Abfragefehler",
            text: "Die aktuelle Bestellung konnte nicht abgefragt werden",
          });
        });
    },
    updatePickupStatus() {
      if (this.viewPane == 2 && this.Customer.roboters.length > 0) {
        this.$store
          .dispatch("order/fetchActualOrder", this.User._id)
          .then(() => {});
      } else {
        return false;
      }
    },
    updateResteMealList() {
      if (
        this.viewPane == 4 &&
        this.$store.getters["isUpdateMealListRunning"]
      ) {
        let lieferort = this.$store.getters["order/getSelectedLieferort"];

        this.$store
          .dispatch("meal/fetchUnpickedMeals", lieferort)
          .then(() => {});
      }
    },
    showLieferortSelectorDialog() {
      this.$store.commit("order/TOGGLE_LIEFERORTE_SELECTOR", true);
    },
    loadOrders() {
      this.$store
        .dispatch("order/fetchOrders", this.User._id)
        .then(() => {
          this.viewPane = 3;
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Abfragefehler",
            text: "Bestellungen konnten nicht abgefragt werden",
          });
        });
    },
    getDayOfWeek(day) {
      return moment().isoWeekday(day).format("dd");
    },

    toggleFilter(kategorie) {
      const filter = this.filterKategorien.find(
        (item) => String(item).toLowerCase() === String(kategorie).toLowerCase()
      );
      if (filter) {
        this.filterKategorien = this.filterKategorien.filter(
          (item) => item != kategorie
        );
      } else {
        this.filterKategorien.push(kategorie);
      }
    },
    loadMeals(startdate, enddate) {
      this.$store
        .dispatch("meal/fetchMealList", { startdate, enddate })
        .then(() => {
          //TOTO Fetch order
        })
        .catch((error) => {
          console.log(error);
        });
    },

    filteredMeals() {
      if (this.filterKategorien && this.filterKategorien.length > 0) {
        return this.$store.getters["meal/getMealList"].filter((item) => {
          return this.filterKategorien.find(
            (filter) => filter === item.kategorie
          );
        });
      } else {
        return this.$store.getters["meal/getMealList"];
      }
    },

    filteredAllergene(meals) {
      const filterAllergene = this.filterAllergene;
      if (filterAllergene && filterAllergene.length > 0) {
        return meals.filter((item) => {
          return !item.allergene.some((r) => filterAllergene.indexOf(r) >= 0);
        });
      } else {
        return meals;
      }
    },

    fetchData() {
      //1. Get customer from customer id in user object
      //2. fetch order dates from server
      //3. init order with dates
      //4. fetch mealdata

      return new Promise((resolve, reject) => {
        if (this.User && this.User.customerId !== "system") {
          this.$store.commit("SET_APP_LOADING", true);
          //1. Get customer from customer id in user object
          this.$store
            .dispatch("customer/fetchCustomer", this.User.customerId)
            .then(async (customer) => {
              try {
                //calculate order end time and date of customer
                const orderEndTime = String(customer.bestellendeZeit).split(
                  ":"
                );
                const orderEnd = moment()
                  .isoWeekday(Number.parseInt(customer.bestellendeTag))
                  .hour(orderEndTime[0])
                  .minute(orderEndTime[1])
                  .second(0);

                //Überprüfe ob Abteilung vorhanden ist, ansonsten verwende default
                let department = null;
                if (this.Customer && this.User) {
                  department = this.Customer.essenskosten.find(
                    (el) => el.name === this.User.department
                  );
                }
                if (!department) {
                  console.log("department does not exist");
                  this.$store.commit("user/SET_USER_DEPARTMENT", "default");
                }

                //Wenn Bestellende erreicht dann setze das Bestellende auf nächste Woche
                /*  if (orderEnd.isBefore(moment())) {
              orderEnd.isoWeek(moment().isoWeek() + 1);
            }*/

                //2. fetch order dates from server and
                //3. init order with dates
                const dates = await this.$store.dispatch(
                  "order/fetchOrderDates",
                  orderEnd
                );

                return Promise.resolve({ customer, orderDates: dates });
              } catch (error) {
                Promise.reject(error);
              }
            })
            .then((data) => {
              return this.$store.dispatch("order/initOrder", {
                user: this.User,
                customer: data.customer,
                orderDates: data.orderDates,
              });
            })
            .then(() => {
              const dates = this.$store.getters["order/getOrderZeitraum"];

              this.loadMeals(
                dates[0].format("YYYY-MM-DD"),
                dates[1].format("YYYY-MM-DD")
              );
              return resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              this.$store.commit("SET_APP_LOADING", false);
            });
        }
      });
    },

    lieferortName(id) {
      let lieferorte = this.userLieferorte();
      if (lieferorte && lieferorte.length > 1) {
        let found = lieferorte.find((el) => el._id === id);
        if (found) {
          return found.standort;
        }
      }
      return "Unbekannt";
    },

    userLieferorte() {
      let lieferorte = [];
      //1. Prüfen ob die Daten von User und Customer vorhanden sind
      if (this.User && this.Customer) {
        //2a. Wenn es nur einen Lieferort gibt, dann diesen automatisch auswählen (es muss mind. einen Liferort geben)
        if (this.Customer.anlieferorte.length == 1) {
          lieferorte = this.Customer.anlieferorte[0];
          return lieferorte;
        }

        //2b. Wenn es mehrere Lieferorte gibt, prüfe welcher Lieferort in der Abteilung des Benutzers ist
        let userDepartment = this.Customer.essenskosten.find(
          (el) => el.name === this.User.department
        );

        if (!userDepartment) {
          userDepartment = this.Customer.essenskosten.find(
            (el) => el.name === "default"
          );
        }

        if (!userDepartment || !userDepartment.lieferort) {
          return lieferorte;
        }

        if (!userDepartment.showRestekarte) {
          userDepartment.showRestekarte = "not";
        }
        //Durchlaufe alle Lieferorte die in der Abteilung gespeichert sind
        for (let lieferort of userDepartment.lieferorte) {
          //Finde die id des Lieferortes in den Kunden Lieferorten
          let found = this.Customer.anlieferorte.find(
            (el) => el._id === lieferort
          );
          //Wenn Lieferort gefunden wurde, fühe diesen zum Array hinzu
          if (found) {
            lieferorte.push(found);
          }
        }
      }
      // console.log(lieferorte);
      return lieferorte;
    },
  },
  computed: {
    appVersion() {
      return this.$store.getters.appVersion;
    },
    passwordConfirmationRule() {
      return () =>
        this.password === this.passwordconfirm ||
        "Die Passwörter stimmen nicht überein";
    },
    loggedIn() {
      return this.$store.getters["user/loggedIn"];
    },
    isBestellEnde() {
      return this.$store.getters["order/isBestellEnde"];
    },
    isShowOnlyGrossgebindeMeals() {
      return this.$store.getters["meal/isShowOnlyGrossgebindeMeals"];
    },

    userDepartment() {
      let department = null;
      if (this.Customer && this.User) {
        department = this.Customer.essenskosten.find(
          (el) => el.name === this.User.department
        );
      }
      // console.log(department);
      //Wenn das department nicht mehr vorhanden ist, setze es auf default
      if (!department) {
        department = this.Customer.essenskosten.find(
          (el) => el.name === "default"
        );
      }

      if (!department.showRestekarte) {
        department.showRestekarte = "not";
      }

      if (!department.showOnlyGrossgebindeMeals) {
        department.showOnlyGrossgebindeMeals = false;
      }

      return department;
    },

    orderMode: {
      get() {
        return this.$store.getters["order/getOrderMode"];
      },
      set(value) {
        this.$store.commit("order/SET_ORDER_MODE", value);
      },
    },
    Customer: {
      get() {
        return this.$store.getters["customer/getCustomer"];
      },
      set(value) {
        this.$store.commit("customer/SET_CUSTOMER", value);
      },
    },
    viewPane: {
      get() {
        return this.$store.getters["getViewPane"];
      },
      set(id) {
        this.$store.commit("SET_VIEW_PANE", id);
        if (id == 2) {
          this.$store.commit(
            "SET_PICKUP_UPDATE_INTERVAL",
            setInterval(this.updatePickupStatus, 3000)
          );
        } else {
          if (this.$store.getters["getPickupUpdateInterval"]) {
            clearInterval(this.$store.getters["getPickupUpdateInterval"]);
          }
        }

        if (id == 4) {
          this.$store.commit("TOGGLE_UPDATE_MEAL_LIST_RUNNING", true);
          this.$store.commit(
            "SET_RESTEMEAL_UPDATE_INTERVAL",
            setInterval(this.updateResteMealList, 3000)
          );
        }
      },
    },
    User() {
      return this.$store.getters["user/getUser"];
    },

    selectedLieferort() {
      return this.$store.getters["order/getSelectedLieferort"];
    },

    filterKategorien: {
      get() {
        return this.$store.getters["meal/getFilterKategorien"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_KATEGORIEN", value);
      },
    },

    filterAllergene: {
      get() {
        return this.$store.getters["meal/getFilterAllergene"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_ALLERGENE", value);
      },
    },

    Meals() {
      let meals = this.filteredMeals();
      meals = this.filteredAllergene(meals);
      return meals;
    },
  },

  created() {
    moment.locale("de-de");

    if (!this.loggedIn) {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch(() => {});
    }

    this.fetchData()
      .then(() => {
        //Set User Lieferort
        let lieferorte = this.userLieferorte();
        this.$store.commit("user/SET_USER_LIEFERORTE", lieferorte);

        //console.log("department", this.userDepartment);

        // console.log("all done");
        if (this.viewPane == 1) {
          this.loadOrderPane();
        } else if (this.viewPane == 2) {
          this.loadPickup();
        } else if (this.viewPane == 3) {
          this.loadOrders();
        }
      })
      .then(() => {
        let fcm = this.$store.getters["getFCMInstance"];

        if ("Notification" in window) {
          //console.log(Notification.permission);
          if (Notification.permission === "granted") {
            return this.$store.dispatch("user/subscriptionCheck", fcm);
          } else if (Notification.permission === "default") {
            return this.$store.dispatch("user/subscribePush", fcm);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //place your reentry code
          //console.log("Login fehler");
          this.$router.push({ name: "login" });
        } else {
          // console.log(error);
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Abfragefehler",
            text: "Kundendaten konnten nicht abgefragt werden.",
          });
        }
      });
  },
  update() {
    if (!this.loggedIn) {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch(() => {});
    }
  },
};
</script>

<style scoped>
.cardOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
.cardTitle {
  text-align: center;
  margin: 0px auto;
  font-size: 1.3em;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
