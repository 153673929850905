<template>
  <v-container class="borderContainer">
    <v-row fluid justify="center">
      <v-chip label class="ma-2 text--white" color="secondary"
        >{{ zeit.von }} - {{ zeit.bis }}</v-chip
      >
      <Amount
        class="mb-2"
        v-model="orderAmount"
        :min="min"
        :max="max"
        @decrement="decrementMeal"
        @increment="incrementMeal"
        unit="Stk."
      />
    </v-row>
    <v-row> </v-row>
  </v-container>
</template>

<script>
import Amount from "@/components/Amount";

export default {
  name: "MealTimeSelector",
  components: { Amount },
  props: {
    zeit: {
      type: Object,
      reqired: true,
    },
    keyname: {
      type: String,
      required: true,
    },

    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 10,
    },
  },
  data: () => ({
    amount: 0,
  }),
  methods: {
    incrementMeal() {
      const dailyAmount = this.dailyTimeslotAmount(this.keyname);

      if (
        (this.orderAmount < this.max && this.departmentLimit <= 0) ||
        (this.orderAmount < this.departmentLimit &&
          dailyAmount < this.departmentLimit)
      ) {
        let amount = this.orderAmount + 1;
        this.$emit("change", { amount: amount, timeIndex: this.keyname });

        this.$store.commit("order/SET_MEAL_AMOUNT", {
          index: this.keyname,
          amount: amount,
          item: this.Meal,
          lieferort: this.selectedLieferort,
        });

        this.$store
          .dispatch("order/saveOrder")
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      }
    },
    decrementMeal() {
      if (this.orderAmount > this.min) {
        let amount = this.orderAmount - 1;
        this.$emit("change", { amount: amount, timeIndex: this.keyname });
        this.$store.commit("order/SET_MEAL_AMOUNT", {
          index: this.keyname,
          amount: amount,
          item: this.Meal,
          lieferort: this.selectedLieferort,
        });

        this.$store
          .dispatch("order/saveOrder")
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      }
    },
    dailyTimeslotAmount(timeSlot) {
      const dayOrders = this.$store.getters["order/getOrder"].days;
      let amount = 0;
      if (dayOrders) {
        for (let index in dayOrders) {
          const dayItem = dayOrders[index];
          if (
            dayItem.meal.kategorie === this.Meal.kategorie &&
            dayItem.timeSlot === String(timeSlot) &&
            dayItem.day === this.selectedDay.format("DD.MM.YYYY")
          ) {
            amount += dayItem.amount;
          }
        }
      }
      return amount;
    },
  },
  computed: {
    Customer() {
      return this.$store.getters["customer/getCustomer"];
    },
    Order() {
      const order = this.$store.getters["order/getOrder"];
      return order;
    },
    User() {
      return this.$store.getters["user/getUser"];
    },
    selectedDay() {
      const day = this.$store.getters["order/getSelectedDay"];
      return day;
    },
    selectedLieferort() {
      return this.$store.getters["order/getSelectedLieferort"];
    },
    departmentLimit() {
      let limit = -1;
      const department = this.Customer.essenskosten.find(
        (el) => el.name === this.User.department
      );
      if (department && department.limits) {
        limit =
          department.limits[String(this.Meal.kategorie).toLowerCase()].amount ||
          -1;
      }
      return limit;
    },
    Meal() {
      return this.$store.getters["meal/getCurrent"];
    },
    orderAmount() {
      const dayOrders = this.$store.getters["order/getSelectedDayOrders"];
      if (dayOrders instanceof Array) {
        const meal = dayOrders.find(
          (el) => el.meal._id == this.Meal._id && el.timeSlot == this.keyname
        );

        if (meal) {
          return meal.amount;
        }
      }

      return 0;
    },
  },
};
</script>
<style scoped>
.amount {
  font-size: 14px !important;
  min-height: 40px;
}
.borderContainer {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}
</style>
