<template>
  <v-container fluid>
    <v-container>
      <v-row v-for="(item, index) in items" :key="index" class="mb-2 mealRow">
        <v-col xs="12">
          <v-row>
            <v-col class="mealTitle" cols="10" @click="showPopup(item)">
              {{ item.name }}
            </v-col>
            <v-col cols="2" v-if="!item.count">
              <div
                class="d-flex justify-end"
                v-if="activeMahlzeiten == 1 && !isGrossgebindeOrder"
              >
                <v-icon
                  v-show="orderAmount(item) < 10"
                  color="green lighten-2"
                  @click="quickAdd(item)"
                >
                  mdi-plus-circle-outline</v-icon
                >
              </div>
            </v-col>
            <v-col cols="2" v-else>
              <span class="mealCount">{{ item.count }} Stk.</span>
            </v-col>
          </v-row>
          <v-row class="mt-0" @click="showPopup(item)">
            <v-col class="mt-0">
              <v-badge
                v-if="!hideBasket"
                class="mr-4"
                v-show="isMealOrderd(item._id)"
                color="green"
                overlap
                :content="isMealOrderd(item._id)"
                ><v-icon color="white">mdi-basket</v-icon></v-badge
              >
              <v-chip small :color="kategorieFarbe(item)"
                >{{ item.kategorie }}
                {{ item.subkategorie ? ` - ${item.subkategorie}` : "" }}</v-chip
              >
            </v-col>

            <v-col class="text-right">
              {{ mealRating(item) }} ( {{ item.feedbacks.length }} )
              <v-icon color="green">mdi-chef-hat</v-icon>
            </v-col>
          </v-row>

          <v-row
            no-gutters
            class="mt-2 d-flex justify-end"
            @click="showPopup(item)"
          >
            <v-col xs="3" class="mealPreis d-flex align-center"
              >€ {{ currencyFormat(inklPreis(item.preis)) }}
            </v-col>

            <v-col class="justify-end">
              <div class="d-flex justify-end">
                <v-img
                  v-show="!!item.glutenfrei"
                  src="@/assets/icons/Glutenfrei.png"
                  max-width="32px"
                  max-height="32px"
                />
              </div>
            </v-col>
            <v-col class="text-right">
              <div class="d-flex justify-end">
                <v-img
                  v-show="!!item.laktosefrei"
                  src="@/assets/icons/Laktosefrei.png"
                  max-width="32px"
                  max-height="32px"
                />
              </div>
            </v-col>
            <v-col class="text-right">
              <div class="d-flex justify-end">
                <v-img
                  v-show="!!item.vegetarisch"
                  src="@/assets/icons/Vegetarisch.png"
                  max-width="32px"
                  max-height="32px"
                />
              </div>
            </v-col>
            <v-col class="text-right">
              <div class="d-flex justify-end">
                <v-img
                  v-show="!!item.schweinefleischfrei"
                  src="@/assets/icons/Schweinefleischfrei.png"
                  max-width="32px"
                  max-height="32px"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      class="fill-height"
      v-model="popup"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="fill-height">
        <v-toolbar dark>
          <v-btn icon @click="closePopup"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-toolbar-title>Gerichtdetails</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentItem && !currentItem.count"
            icon
            @click="closePopup"
            ><v-icon>mdi-check</v-icon></v-btn
          >
        </v-toolbar>

        <MealCard
          @pickupMeal="pickupMeal"
          @qr-code-dialog-closed="qrCodePickupClosed"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import MealCard from "@/components/meals/MealCard.vue";
import moment from "moment";

export default {
  name: "MealList",
  props: {
    items: {
      type: Array,
      required: true,
    },
    hideBasket: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  components: { MealCard },
  methods: {
    pickupMeal(data) {
      let pickupData = { ...data, pickupStatus: false, pickupError: "" };
      this.$store
        .dispatch("order/updateUnpickedMeal", data)
        .then((result) => {
          pickupData.pickupStatus = true;
          pickupData.pickupResult = result;
          // return this.$store.dispatch("meal/fetchUnpickedMeals", "default");
        })
        .catch((error) => {
          pickupData.pickupStatus = false;
          pickupData.pickupError = error;
        })
        .finally(() => {
          this.closePopup();
          this.$emit("pickupMeal", pickupData);
        });
    },
    qrCodePickupClosed() {
      this.closePopup();
    },
    quickAdd(item) {
      let amount = this.orderAmount(item) + 1;
      if (amount <= 10) {
        this.$store.commit("order/SET_MEAL_AMOUNT", {
          index: 0,
          amount: amount,
          item: item,
        });

        this.$store
          .dispatch("order/saveOrder")
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      }
    },

    orderAmount(item) {
      const dayOrders = this.$store.getters["order/getSelectedDayOrders"];
      if (dayOrders instanceof Array) {
        const meal = dayOrders.find(
          (el) => el.meal._id == item._id && el.timeSlot == 0
        );
        if (meal) {
          return meal.amount;
        }
      }
      return 0;
    },
    inklPreis(price) {
      let mwst = (110 * price) / 100;
      return mwst;
    },
    allergenIcon(allergen) {
      let src = "";
      const kuerzel = String(allergen).toUpperCase().trim();
      if (kuerzel == "A") {
        src = "Getreide.png";
      } else if (kuerzel == "B") {
        src = "Krebstiere.png";
      } else if (kuerzel == "C") {
        src = "Eier.png";
      } else if (kuerzel == "D") {
        src = "Fisch.png";
      } else if (kuerzel == "E") {
        src = "Erdnüsse.png";
      } else if (kuerzel == "F") {
        src = "Soja.png";
      } else if (kuerzel == "G") {
        src = "Milch.png";
      } else if (kuerzel == "H") {
        src = "Schalenfrüchte.png";
      } else if (kuerzel == "L") {
        src = "Sellerie.png";
      } else if (kuerzel == "M") {
        src = "Senf.png";
      } else if (kuerzel == "N") {
        src = "Sesam.png";
      } else if (kuerzel == "O") {
        src = "Sulfite.png";
      } else if (kuerzel == "P") {
        src = "Lupinen.png";
      } else if (kuerzel == "R") {
        src = "Weichtiere.png";
      }
      return require("@/assets/icons/" + src);
    },
    getAllergenName(value) {
      const allergen = this.Allergene.find(
        (item) =>
          String(item.value).toLowerCase() === String(value).toLowerCase()
      );
      if (allergen) {
        return allergen.text;
      }
      return "";
    },
    showPopup(item) {
      this.currentItem = item;
      this.popup = true;
    },
    closePopup() {
      this.popup = false;
    },

    currencyFormat(value = 0) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    kategorieFarbe(item) {
      if (item.kategorie == "Vorspeisen") {
        return "red lighten-2";
      } else if (item.kategorie == "Hauptspeisen") {
        return "green lighten-2";
      } else if (item.kategorie == "Desserts") {
        return "orange lighten-2";
      }
    },
    mealRating(item) {
      let sum = 0;
      for (let index in item.feedbacks) {
        if (!item.feedbacks[index]) {
          continue;
        }
        sum += item.feedbacks[index].rating;
      }
      return item.feedbacks.length / sum || 0;
    },
    isMealOrderd(mealid) {
      let orders = this.getDayOrders.filter((el) => el.meal._id === mealid);
      let amount = 0;
      if (orders) {
        for (let index in orders) {
          amount += Number(orders[index].amount);
        }
        return amount;
      }
      return false;
    },
  },

  computed: {
    isGrossgebindeOrder() {
      return this.$store.getters["order/isGrossgebindeOrder"];
    },
    getDayOrders() {
      const dayOrders = this.$store.getters["order/getSelectedDayOrders"];
      return dayOrders;
    },
    activeMahlzeiten() {
      let count = 0;
      for (let index in this.Customer.mahlzeiten) {
        if (this.Customer.mahlzeiten[index].active) {
          count++;
        }
      }
      return count;
    },
    Customer() {
      return this.$store.getters["customer/getCustomer"];
    },
    Order() {
      return this.$store.getters["order/getOrder"];
    },
    Allergene() {
      return this.$store.getters["meal/getAllergene"];
    },
    kategorien() {
      return this.$store.getters["meal/getKategorien"];
    },
    mealSearch() {
      return this.$store.getters["meal/getMealSearch"];
    },

    currentItem: {
      get() {
        return this.$store.getters["meal/getCurrent"];
      },
      set(value) {
        this.$store.commit("meal/SET_MEAL", value);
      },
    },

    popup: {
      get() {
        return this.$store.getters["meal/isMealPopupShown"];
      },
      set(value) {
        this.$store.commit("meal/TOGGLE_MEAL_POPUP", value);
      },
    },

    viewPane: {
      get() {
        return this.$store.getters["getViewPane"];
      },
      set(id) {
        this.$store.commit("SET_VIEW_PANE", id);
      },
    },
    orderZeitraum() {
      const current = moment();
      const nextWeek = moment().isoWeek(current.isoWeek() + 1);
      const firstKWDay = moment(nextWeek).isoWeekday(1);
      const lastKWDay = moment(nextWeek).isoWeekday(7);
      return (
        firstKWDay.format("DD.MM.YYYY") +
        " bis " +
        lastKWDay.format("DD.MM.YYYY") +
        " ( KW: " +
        nextWeek.isoWeek() +
        " )"
      );
    },
    bestellEnde() {
      const bestellEndeTag = Number.parseInt(
        this.$store.getters["customer/getCustomer"].bestellendeTag
      );
      const bestellTimeParts =
        this.$store.getters["customer/getCustomer"].bestellendeZeit.split(":");
      const bestellEnde = moment()
        .isoWeekday(bestellEndeTag)
        .hour(bestellTimeParts[0])
        .minute(bestellTimeParts[1])
        .second(0);

      return bestellEnde.format("DD.MM.YYYY HH:mm") + " Uhr";
    },
    isBestellEnde() {
      return this.$store.getters["order/isBestellEnde"];
    },
  },

  created() {},
};
</script>

<style scoped>
#Gerichtlistcard {
  margin: 20px;
}

.mealPreis {
  font-size: 18px;
}

.mealRow {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 5px;
}

.mealTitle {
  font-weight: bold;
}

.propImage {
  display: inline-block;
  margin-left: 10px;
}

.popup {
  background-color: white !important;
  height: 100%;
}

.mealCount {
  font-weight: bold;
}
</style>
