<template>
  <v-container class="borderContainer">
    <v-row fluid>
      <v-chip label class="ma-2 text--white" color="secondary"
        >{{ zeit.von }} - {{ zeit.bis }}</v-chip
      >
    </v-row>
    <v-row fluid class="actionRow">
      <v-col>
        <v-text-field
          v-model="amount"
          label="Portionen"
          dense
          hide-details
          color="secondary"
          @focus="$event.target.select()"
          @change="changeAmount()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MealTimeSelectorGrossgebinde",
  props: {
    zeit: {
      type: Object,
      reqired: true,
    },
    keyname: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 10,
    },
  },
  data: () => ({
    amount: 0,
  }),
  methods: {
    changeAmount() {
      this.$store.commit("order/SET_MEAL_AMOUNT", {
        index: this.keyname,
        amount: Number(this.amount),
        item: this.Meal,
        lieferort: this.lieferort,
      });
      this.$store
        .dispatch("order/saveOrder")
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      this.$emit("change", {
        amount: Number(this.amount),
        timeIndex: this.keyname,
      });
    },

    dailyTimeslotAmount(timeSlot) {
      const dayOrders = this.$store.getters["order/getOrder"].days;
      let amount = 0;
      if (dayOrders) {
        for (let index in dayOrders) {
          const dayItem = dayOrders[index];
          if (
            dayItem.meal.kategorie === this.Meal.kategorie &&
            dayItem.timeSlot === String(timeSlot) &&
            dayItem.day === this.selectedDay.format("DD.MM.YYYY")
          ) {
            amount += Number(dayItem.amount);
          }
        }
      }
      return amount;
    },
  },
  computed: {
    Customer() {
      return this.$store.getters["customer/getCustomer"];
    },
    Order() {
      const order = this.$store.getters["order/getOrder"];
      return order;
    },
    User() {
      return this.$store.getters["user/getUser"];
    },
    selectedDay() {
      const day = this.$store.getters["order/getSelectedDay"];
      return day;
    },
    departmentLimit() {
      let limit = -1;
      const department = this.Customer.essenskosten.find(
        (el) => el.name === this.User.department
      );
      if (department && department.limits) {
        limit =
          department.limits[String(this.Meal.kategorie).toLowerCase()].amount ||
          -1;
      }
      return limit;
    },
    lieferort() {
      return this.$store.getters["order/getCurrentLieferort"];
    },
    Meal() {
      return this.$store.getters["meal/getCurrent"];
    },
    orderAmount() {
      const dayOrders = this.$store.getters["order/getSelectedDayOrders"];
      if (dayOrders instanceof Array) {
        const meal = dayOrders.find(
          (item) =>
            item.meal._id == this.Meal._id &&
            item.timeSlot == this.keyname &&
            item.lieferort == this.lieferort
        );
        if (meal) {
          return Number(meal.amount);
        }
      }
      return 0;
    },
  },
  watch: {
    lieferort() {
      this.amount = this.orderAmount;
    },
    orderAmount(val) {
      this.amount = val;
    },
  },
  created() {
    this.amount = this.orderAmount;
  },
};
</script>
<style scoped>
.amount {
  font-size: 14px !important;
}

.borderContainer {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
</style>
