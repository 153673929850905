<template>
  <v-footer app dark fixed padless>
    <v-card flat tile dark width="100%">
      <v-card-text class="pa-2 white--text" align="center">
        <v-fade-transition>
          <div v-show="Order.status != 'closed'">
            <v-btn
              :disabled="!canOrder"
              @click="saveOrder"
              color="success"
              block
              >Bestellung abschließen</v-btn
            >
            <div>
              <v-icon class="ma-1">mdi-information</v-icon> Du kannst deine
              Bestellung nach dem speichern bis zum Bestellende noch abändern.
            </div>
          </div>
        </v-fade-transition>

        <div class="copyright">
          {{ new Date().getFullYear() }} - {{ copyright }}
        </div>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: function () {
    return {
      name: "FooterOverview",
    };
  },
  props: {
    copyright: {
      type: String,
      default: "Anewo",
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saveOrder() {
      this.Order.orderDate = new Date();
      this.Order.status = "saved";
      this.$store
        .dispatch("order/saveOrder")
        .then(() => {
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Erfolgreich",
            text: "Deine Bestellung wurde gespeichert",
          });
          this.viewPane = 0;
          this.orderPane = 0;
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Speicherfehler",
            text: "Leider konnte die Bestellung nicht gespeichert werden.",
          });
        });
    },
  },
  computed: {
    viewPane: {
      get() {
        return this.$store.getters["getViewPane"];
      },
      set(id) {
        this.$store.commit("SET_VIEW_PANE", id);
      },
    },
    User() {
      return this.$store.getters["user/getUser"];
    },
    orderPane: {
      get() {
        return this.$store.getters["getOrderPane"];
      },
      set(id) {
        this.$store.commit("SET_ORDER_PANE", id);
      },
    },
    Order() {
      return this.$store.getters["order/getOrder"];
    },
    daySortedOrder() {
      return this.$store.getters["order/daySortedOrder"];
    },
    canOrder() {
      return this.Order.status != "closed" && this.daySortedOrder.length > 0;
    },

    filterKategorien: {
      get() {
        return this.$store.getters["meal/getFilterKategorien"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_KATEGORIEN", value);
      },
    },
  },
};
</script>

<style scoped>
#logo {
  display: inline-block;
  min-width: 100px;
  max-height: 14px;
  width: 150px;
}

#datafieberlink {
  vertical-align: middle;
}

.copyright {
  margin-top: 10px;
}
</style>
