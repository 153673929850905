<template>
  <v-layout class="pa-0" justify-center align-center>
    <span v-if="label" class="mr-3">{{ label }}</span>
    <v-btn small icon outlined @click="decrementMeal()">
      <v-icon>mdi-minus</v-icon>
    </v-btn>
    <span class="pa-1 ml-1 mr-1 amount">{{ amount }}</span
    ><span v-if="unit" class="unit mr-1"> {{ unit }}</span>
    <v-btn small icon outlined @click="incrementMeal()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
export default {
  name: "Amount",
  props: {
    max: {
      type: Number,
      required: false,
    },
    min: {
      type: Number,
      required: false,
    },
    value: {
      type: Number,
      reuqired: false,
    },
    unit: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    amount: 0,
  }),
  methods: {
    incrementMeal() {
      if (this.amount >= this.max) {
        return;
      }
      this.amount++;
      this.$emit("increment", this.amount);
    },
    decrementMeal() {
      if (this.amount <= this.min) {
        return;
      }
      this.amount--;

      this.$emit("decrement", this.amount);
    },
  },
  watch: {
    amount(val) {
      this.$emit("changed", val);
    },
    value(val) {
      if ((this.min && val < this.min) || (this.max && val > this.max)) {
        return;
      }
      this.amount = val;
    },
  },
  created() {
    this.amount = this.value;
  },
};
</script>

<style scopeds>
.amount {
  font-size: 14px !important;
  /* min-height: 40px;*/
}
.unit {
  font-size: 14px !important;
}
</style>
