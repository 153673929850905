<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card tile>
      <v-card-title class="pb-2">
        <span v-if="orderMode === 'single'">Deine Bestellung</span>
        <span v-else-if="orderMode === 'big'">Großbestellung</span>
        <span v-else-if="orderMode === 'bulk'">Großgebinde Bestellung</span>
        <span style="font-size: 12px" class="ml-2">
          (Stand: {{ lastOrderSave }})</span
        ></v-card-title
      >
      <v-card-text
        >Für den Zeitraum: {{ getDate(orderDays[0]) }} bis
        {{ getDate(orderDays[orderDays.length - 1]) }}
        <br />
        Bestellende: {{ bestellEnde.format("DD.MM.YYYY HH:mm") }} Uhr<br />
        Status:
        <v-chip small v-show="currentOrder.status === 'open'">Offen</v-chip>
        <v-chip
          small
          color="green"
          dark
          v-show="currentOrder.status === 'saved'"
          >Bestätigt</v-chip
        >
        <v-chip
          small
          color="red"
          dark
          v-show="currentOrder.status === 'canceled'"
          >Storniert</v-chip
        >
      </v-card-text>
    </v-card>

    <v-container fluid>
      <v-row v-for="(day, index) in daySortedOrder" :key="index">
        <v-col>
          <v-card class="">
            <v-card-title>
              {{ getDayName(day.day) }} {{ day.day }}
            </v-card-title>
            <v-card-text>
              <div v-for="(item, mealIndex) in day.meals" :key="mealIndex">
                <v-row class="mealRow pt-2">
                  <v-col cols="2"> {{ item.totalAmount }}x </v-col>
                  <v-col>
                    {{ item.meal.name }}
                  </v-col>
                  <v-col cols="3" xs="3" class="text-right">
                    {{
                      currencyFormat(
                        inklPreis(item.meal.preis) * item.totalAmount
                      )
                    }}
                    €
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    v-for="(timeSlot, timeIndex) in item.timeSlots"
                    :key="timeIndex"
                  >
                    <v-chip
                      small
                      v-show="timeSlot"
                      class="mr-1"
                      @click="showPopup(item, day.day)"
                    >
                      {{ timeSlot }}x - {{ getTimeSlot(timeIndex) }}
                      <v-icon class="ml-2" small>mdi-pencil</v-icon>
                    </v-chip>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-text>
              <v-divider />
              <v-row>
                <v-col class="text-right">
                  <div>
                    Tagessumme: €
                    <span class="dayPrice">{{
                      currencyFormat(getDayPrice(day))
                    }}</span>
                  </div>
                  <div
                    v-if="orderMode === 'single' && getDayZuschuss(day) > 0.0"
                  >
                    abzüglich Firmenzuschuss: €
                    <span class="dayPrice">{{
                      currencyFormat(getDayZuschuss(day))
                    }}</span>
                  </div>

                  <div
                    v-if="orderMode === 'single' && getDayZuschuss(day) > 0.0"
                  >
                    Aufzuzahlen
                    <span class="dayPrice">{{
                      currencyFormat(
                        Math.abs(getDayPrice(day) - getDayZuschuss(day))
                      )
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-if="orderMode === 'single' && currentOrder.status !== 'canceled'"
      >
        <v-col>
          <v-card>
            <v-card-title>
              {{ zuschussBetragTotal > 0.0 ? "Aufzuzahlen" : "Summe" }}
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col dense class="text-left">
                  <div v-if="getWeekPriceInkl - zuschussBetragTotal === 0">
                    Kein Betrag aufzuzahlen, deine Kosten werden vollständig
                    übernommen.
                  </div>
                  <div v-else>
                    <span style="font-weight: bold; font; "
                      >Betrag
                      {{
                        zuschussBetragTotal > 0.0
                          ? "aufzuzahlen"
                          : "zu bezahlen"
                      }}
                      inkl 10% MWSt.: €</span
                    >
                  </div>
                </v-col>
                <v-col
                  cols="3"
                  dense
                  class="text-right"
                  v-if="getWeekPriceInkl - zuschussBetragTotal !== 0"
                >
                  <span class="dayPrice"
                    >{{
                      currencyFormat(getWeekPriceInkl - zuschussBetragTotal)
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mb-8" v-show="currentOrder.status === 'saved'">
            <v-card-title>Bestellung stornieren</v-card-title>
            <v-card-text>
              Deine Bestellung wurde bereits bestätigt. Du kannst deine
              Bestellung hier stornieren.
              <v-btn block color="error" outlined @click="stornoOrder"
                >Stornieren</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      class="fill-height"
      v-model="popup"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="fill-height">
        <v-toolbar dark>
          <v-btn icon @click="popup = false"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-toolbar-title>Gerichtdetails</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="popup = false"><v-icon>mdi-check</v-icon></v-btn>
        </v-toolbar>

        <MealCard />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
import MealCard from "@/components/meals/MealCard.vue";
import moment from "moment";

export default {
  name: "OrderOverview",
  components: { MealCard },
  data: () => ({
    orderTotalPrice: 0,
  }),
  methods: {
    gotoOrder(day) {
      this.selcetedDay = moment(day.day, "DD.MM.YYYY");
      this.orderPane = 0;
    },
    showPopup(item, day) {
      this.selcetedDay = moment(day, "DD.MM.YYYY");
      this.currentItem = item.meal;
      this.popup = true;
    },
    getDayName(date) {
      if (!(date instanceof moment)) {
        date = moment(date, "DD.MM.YYYY");
      }
      return date.format("dddd");
    },
    getDate(date) {
      return date.format("DD.MM.YYYY");
    },
    getTimeSlot(index) {
      const timeSlot = this.Customer.mahlzeiten[index];
      return `${timeSlot.von} - ${timeSlot.bis}`;
    },
    currencyFormat(value = 0) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    getDayPrice(day) {
      let sum = 0;
      for (let index in day.meals) {
        sum +=
          day.meals[index].totalAmount *
          this.inklPreis(day.meals[index].meal.preis);
      }

      return sum;
    },
    getDayZuschuss(day) {
      if (this.firmenzuschuss) {
        if (this.firmenzuschuss.perItem === 0) {
          //Pro Essen

          let timeSlots = [];
          for (let mealIndex in day.meals) {
            let meal = day.meals[mealIndex];
            for (let slotIndex in meal.timeSlots) {
              if (!timeSlots[slotIndex]) {
                timeSlots[slotIndex] = 0;
              }

              timeSlots[slotIndex] +=
                this.inklPreis(meal.meal.preis) * meal.timeSlots[slotIndex];
            }
          }

          let zuschussGesamt = 0.0;
          for (let slotIndex in timeSlots) {
            let zuschuss = 0.0;
            const slotSumme = timeSlots[slotIndex];
            if (this.firmenzuschuss.type === 0) {
              //Betrag
              zuschuss = slotSumme - Number(this.firmenzuschuss.amount);
              if (zuschuss >= 0.0) {
                zuschussGesamt += Number(this.firmenzuschuss.amount);
              } else {
                zuschussGesamt += slotSumme;
              }
            } else if (this.firmenzuschuss.type === 1) {
              //Prozent
              zuschuss = (Number(this.firmenzuschuss.amount) * slotSumme) / 100;
              zuschussGesamt += zuschuss;
            }
          }

          return zuschussGesamt;
        } else if (this.firmenzuschuss.perItem === 1) {
          //Pro Tag
          let zuschuss = 0.0;
          const tagesSumme = this.getDayPrice(day);
          let abzug = 0.0;
          if (this.firmenzuschuss.type === 0) {
            //Betrag
            zuschuss = tagesSumme - Number(this.firmenzuschuss.amount);
            //Wenn Zuschuss weniger ist als die Tagessumme gib den Zuschuss zurück
            if (zuschuss >= 0.0) {
              abzug = Number(this.firmenzuschuss.amount);
            } else {
              //Wenn der Zuschuss mehr ist als die Tagessumme übernehme volle kosten
              abzug = tagesSumme;
            }
          } else if (this.firmenzuschuss.type === 1) {
            //Prozent
            zuschuss = (Number(this.firmenzuschuss.amount) * tagesSumme) / 100;

            abzug = zuschuss;
          }

          return abzug;
        }
      } else {
        return 0;
      }
    },
    inklPreis(price) {
      let mwst = (110 * price) / 100;
      return mwst;
    },

    stornoOrder() {
      this.$store
        .dispatch("order/stornoOrder")
        .then(() => {
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Erfolgreich",
            text: "Deine Bestellung wurde storniert",
          });
          this.viewPane = 0;
          this.orderPane = 0;
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Speicherfehler",
            text: "Leider konnte die Bestellung nicht gespeichert werden.",
          });
        });
    },
  },
  computed: {
    popup: {
      get() {
        return this.$store.getters["order/isOrderOverviewDetailsShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_ORDER_OVERVIEW_DETAILS", value);
      },
    },
    orderMode: {
      get() {
        return this.$store.getters["order/getOrderMode"];
      },
      set(value) {
        this.$store.commit("order/SET_ORDER_MODE", value);
      },
    },
    currentItem: {
      get() {
        return this.$store.getters["meal/getCurrent"];
      },
      set(value) {
        this.$store.commit("meal/SET_MEAL", value);
      },
    },
    zuschussZeit() {
      if (this.firmenzuschuss) {
        if (this.firmenzuschuss.perItem === 0) {
          return "Essen";
        } else if (this.firmenzuschuss.perItem === 1) {
          return "Tag";
        }
      }
      return "";
    },
    zuschussBetrag() {
      if (this.firmenzuschuss) {
        if (this.firmenzuschuss.type === 0) {
          return this.firmenzuschuss.amount;
        } else if (this.firmenzuschuss.type === 1) {
          return (this.firmenzuschuss.amount * this.getWeekPrice) / 100;
        }
      }
      return 0;
    },

    getWeekPrice() {
      let sum = 0;
      const days = this.daySortedOrder;
      for (let dayIndex in days) {
        for (let mealIndex in days[dayIndex].meals) {
          sum +=
            days[dayIndex].meals[mealIndex].totalAmount *
            days[dayIndex].meals[mealIndex].meal.preis;
        }
      }

      return sum;
    },
    getWeekPriceInkl() {
      let sum = 0;
      const days = this.daySortedOrder;
      for (let dayIndex in days) {
        for (let mealIndex in days[dayIndex].meals) {
          sum +=
            days[dayIndex].meals[mealIndex].totalAmount *
            this.inklPreis(days[dayIndex].meals[mealIndex].meal.preis);
        }
      }

      return sum;
    },
    zuschussBetragTotal() {
      let sum = 0.0;
      const days = this.daySortedOrder;
      for (let dayIndex in days) {
        sum += this.getDayZuschuss(days[dayIndex]);
      }
      return sum;
    },
    orderMwst() {
      let mwst = (10 * this.getWeekPrice) / 100;
      return mwst;
    },
    firmenzuschuss() {
      let zuschuss = this.Customer.essenskosten.find(
        (el) => el.name == this.User.department
      );
      return zuschuss;
    },
    viewPane: {
      get() {
        return this.$store.getters["getViewPane"];
      },
      set(id) {
        this.$store.commit("SET_VIEW_PANE", id);
      },
    },
    selcetedDay: {
      get() {
        return this.$store.getters["order/getSelectedDay"];
      },
      set(date) {
        this.$store.commit("order/SET_SELECTED_DAY", date);
      },
    },
    orderPane: {
      get() {
        return this.$store.getters["getOrderPane"];
      },
      set(value) {
        this.$store.commit("SET_ORDER_PANE", value);
      },
    },
    User() {
      return this.$store.getters["user/getUser"];
    },
    Customer() {
      return this.$store.getters["customer/getCustomer"];
    },

    currentOrder() {
      return this.$store.getters["order/getOrder"];
    },

    orderDays() {
      return this.$store.getters["order/getOrderDays"];
    },
    daySortedOrder() {
      return this.$store.getters["order/daySortedOrder"];
    },
    bestellEnde() {
      return this.$store.getters["order/getBestellEnde"];
    },
    lastOrderSave() {
      return moment(this.currentOrder.modifiedTS).format("DD.MM.YYYY HH:mm:ss");
    },

    filterKategorien: {
      get() {
        return this.$store.getters["meal/getFilterKategorien"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_KATEGORIEN", value);
      },
    },
  },

  created() {},
};
</script>

<style scoped>
.mealRow {
  font-size: 16x;
  font-weight: bold;
}

.dayPrice {
  font-weight: bold;
  width: 60px;
  display: inline-block;
}
</style>
