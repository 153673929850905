<template>
  <v-dialog v-model="showOrderPickupResult" fullscreen>
    <v-card dark @click="showOrderPickupResult = false">
      <v-card-text>
        <v-container v-show="status">
          <v-row>
            <v-col style="text-align: center">
              <h1>Check erfolgreich</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="resultText">
              Dein Gericht wurde gefunden!<br />
              Du kannst dir davon<br />
              <span class="amount">{{ mealAmount }} Stk.</span><br />
              nehmen.
            </v-col>
          </v-row>
          <v-layout align-center justify-center class="mt-4">
            <v-flex align-center style="text-align: center">
              <v-icon size="100" color="green">mdi-check-circle-outline</v-icon>
            </v-flex>
          </v-layout>
        </v-container>
        <v-container v-show="!status">
          <v-row>
            <v-col style="text-align: center">
              <h1>Check fehlgeschlagen</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-show="!pickupError" class="errorText">
              Dein Gericht wurde nicht gefunden!<br />
              Leider trat ein Fehler auf<br />
            </v-col>
            <v-col v-show="pickupError" class="errorText">
              {{ pickupError }}
            </v-col>
          </v-row>
          <v-layout align-center justify-center class="mt-4">
            <v-flex align-center style="text-align: center">
              <v-icon size="100" color="red">mdi-alert-outline</v-icon>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PickupResultDialog",
  props: {
    status: {
      type: Boolean,
      required: true,
    },
    pickupError: {
      type: [String, null],
      default: null,
    },
    mealAmount: {
      type: [String, Number],
      default: 1,
    },
  },
  data: () => ({}),
  methods: {},
  computed: {
    showOrderPickupResult: {
      get() {
        return this.$store.getters["order/isOrderPickupResultDialogShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_ORDER_PICKUP_RESULT", value);
      },
    },
  },
};
</script>

<style></style>
